import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from '../../components/Nav';
import { APP_NAME } from "../../config";
import { Backdrop, Button, CircularProgress, Fab, Grid, Hidden, IconButton, InputAdornment, InputBase, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, withStyles, Zoom } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import api from "../../services/api";
import { Add, DeleteOutline, PlusOne, PlusOneOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import SearchIcon from '@material-ui/icons/Search';

// import { Provider } from 'react-redux';
// import store from '../../store';

const classes = (theme) => ({
    toolbar: {
        ...theme.mixins.toolbar,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        [theme.breakpoints.up('md')]: {
            left: 240,
        },
        top: 90
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
});
class ManutencaoList extends Component {
    state = {
        redirect: false,
        loading: false,
        data: [],
        search: {
            type: "MAINTENANCE",
            title: ""
        },
    }

    componentDidMount() {
        this.props.setTitle("Manutenções");
        document.title = `${APP_NAME} - Manutenção`;
        this.getData();
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect push to='/' />
        }
    }
    getData(e) {
        this.setState({
            ...this.state,
            loading: true
        });
        api.get(`transparence/`, { params: this.state.search })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        redirect: false,
                        data: response.data.data,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 403) {
                    this.setState({
                        redirect: true
                    });
                }
            });
    }
    lista = () => {
        const { classes } = this.props;
        const { data } = this.state;

        if (!this.state.loading && data.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={2}>
                        <Alert severity="warning" className="alert-text-center">Nenhum registro encontrado!</Alert>
                    </TableCell>
                </TableRow>
            )
        } else {
            return (
                data.map(manutencao =>
                    <TableRow hover key={manutencao.id}>
                        <TableCell>
                            <Link to={`/manutencao/${manutencao.id}/editar`}>{manutencao.title}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/manutencao/${manutencao.id}/editar`}>{dayjs(manutencao.created_at).format("DD/MM/YYYY HH:mm")}</Link>
                        </TableCell>
                    </TableRow>
                )
            )
        }
    }
    handleSearch = (e) => {
        this.setState({
            ...this.state,
            search: { type: "MAINTENANCE", title: e.target.value || "" }
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Hidden smUp>
                    <Grid container className="page-title" alignItems="center">
                        <Grid item sm={6} xs={6}>
                            <Typography variant="h5">Manutenção</Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={3} className="table-data">
                            <form onSubmit={(e) => { e.preventDefault(); this.getData() }}>
                                <Grid item xs={12} style={{ padding: "8px" }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="search"
                                        name="search"
                                        onChange={this.handleSearch}
                                        type="text"
                                        placeholder="Procurar"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton type="submit" style={{ padding: 10 }} aria-label="search">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </form>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Título
                                        </TableCell>
                                        <TableCell>
                                            Criado em
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.lista()}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Zoom
                            in={true}
                        >
                            <Tooltip title="Adicionar">
                                <Fab component={Link} to="/manutencao/cadastro/novo" color="primary" className={classes.fab}>
                                    <Add htmlColor={"#FFF"}></Add>
                                </Fab>
                            </Tooltip>
                        </Zoom>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(classes)(ManutencaoList);
