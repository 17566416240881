import React, { Component, useEffect, useState } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Box, Grid, Paper, Button, FormHelperText, withStyles, InputAdornment, TextField, Typography, CircularProgress, makeStyles } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import api from "../../services/api";
import { getIdFarm, login } from "../../services/auth";
import Toast from '../../components/Toast';
import { Link, Redirect, useLocation, useParams } from 'react-router-dom';
import './styles.css';
import { APP_NAME } from "../../config";
import { blue, green } from "@material-ui/core/colors";
import { Lock, Person } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
const useStyles = makeStyles((theme) => (
    {
        formLogin: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            maxWidth: 320,
            margin: 'auto',
            backgroundColor: "transparent"
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            marginBottom: "30px"
        },
        title: {
            color: "#1FD300"
        },
        loginButton: {
            // f79036
            backgroundColor: "#1FD300",
            '&:hover': {
                backgroundColor: green[500],
            },
            color: '#FFF'
        },
    }
));

const RecuperarSenha = () => {
    const params = new URLSearchParams(useLocation().search);
    const token = params.get("token") || false;
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [openNotification, setOpenNotification] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    useEffect(() => {
        document.title = `${APP_NAME} - Recuperar Senha`;
        const validateDate = async () => {
            if (token) {
                // valida o token
                setLoading(true);
                try {
                    await api.get(`/login/forgot-password/validate?token=${token}`);
                    setStep(2);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setOpenNotification(true);
                    setError(error.response.data.msg || "");
                    setStep(1);
                }
            } else {
                setLoading(false);
                setStep(1);
            }
        }
        validateDate();
    }, []);


    const schema = yup.object().shape({
        password: yup.string().required("Informe sua senha")
            .notOneOf(["Passw0rd", "Password123"], "Senha não pode ser Passw0rd ou Password123")
            .min(8, "Senha deve conter no mínimo 8 letras")
            .matches(/[a-z]/g, "Senha deve conter no mínimo 1 letra minúscula")
            .matches(/[A-Z]/g, "Senha deve conter no mínimo 1 letra maiúscula")
            .matches(/^[\S]+$/g, "Senha não pode conter espaço em branco"),
        confirm_password: yup.string().required("Confirme sua senha")
            .oneOf([yup.ref('password'), null], "Senhas não coincidem.")
    });
    const methods = useForm({
        resolver: yupResolver(schema)
    });
    const { handleSubmit, control, reset, errors } = methods;
    const onSubmit = async (data) => {
        data.token = token;
        try {
            let response = await api.put(`/login/forgot-password`, data);
            setLoading(false);
            setStep(4);
        } catch (error) {
            setError(error.response.data.msg);
            setOpenNotification(true);
            setLoading(false);
        }

    };
    const handleEmail = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setEmail(value);
        setDisableButton(!/\S+@\S+\.\S+/.test(value));
    }
    const requestLink = async (event) => {
        event.preventDefault();
        setOpenNotification(false);
        setLoading(true);
        let data = { "email": email };
        try {
            let response = await api.post("/login/forgot-password", data);
            setLoading(false);
            setStep(3);
        } catch (error) {
            setError(error.response.data.msg);
            setOpenNotification(true);
            setLoading(false);
        }
    }
    return (
        <>
            <CssBaseline />
            <Box component="div" className="login-page" style={{ backgroundImage: `url("/images/background-login.jpg")` }}>
                <Container>
                    <Grid container alignItems="center">
                        {
                            step === 1 ? (
                                <Grid item xs={12} sm={12}>
                                    <form onSubmit={requestLink}>
                                        <Paper elevation={0} className={classes.formLogin}>
                                            <Grid container direction="column" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Box className={classes.logo} component="div">
                                                        <Box component="img" src="/images/logo-login.svg" />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" align="center" className={classes.title}>Recuperar senha</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth>
                                                        {/* <InputLabel htmlFor="email">E-mail</InputLabel> */}
                                                        <TextField
                                                            id="email"
                                                            name="email"
                                                            onChange={handleEmail}
                                                            onBlur={handleEmail}
                                                            autoFocus={true}
                                                            autoComplete="username"
                                                            label="Digite seu e-mail"
                                                        /* InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Person />
                                                                </InputAdornment>
                                                            )
                                                        }} */
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button disabled={disableButton || loading} type="submit" size="large" variant="contained" className={classes.loginButton} fullWidth>
                                                        {
                                                            !loading ? (
                                                                "Enviar"
                                                            ) : (
                                                                <CircularProgress style={{ width: 26, height: 26 }} />
                                                            )
                                                        }
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Link to={"/login"} style={{ color: "#177CBF" }}>Voltar</Link>
                                                </Grid>
                                                {openNotification &&
                                                    <Toast type="error" message={error} vertical="bottom" horizontal="center"></Toast>
                                                }
                                            </Grid>
                                        </Paper>
                                    </form>
                                </Grid>
                            ) :
                                step === 2 ? (
                                    <Grid item xs={12} sm={12}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Paper elevation={0} className={classes.formLogin}>
                                                <Grid container direction="column" spacing={4}>
                                                    <Grid item xs={12}>
                                                        <Box className={classes.logo} component="div">
                                                            <Box component="img" src="/images/logo-login.svg" />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" align="center" className={classes.title}>Recuperar senha</Typography>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Controller type="password" as={TextField} error={errors.password ? true : false} helperText={errors.password?.message} fullWidth name="password" label="Digite sua nova senha" control={control} variant="outlined" defaultValue="123456" value="" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controller type="password" as={TextField} error={errors.confirm_password ? true : false} helperText={errors.confirm_password?.message} fullWidth name="confirm_password" label="Confirme a sua nova senha" control={control} variant="outlined" defaultValue="123456" />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Button type="submit" size="large" variant="contained" className={classes.loginButton} fullWidth>
                                                            {
                                                                !loading ? (
                                                                    "Enviar"
                                                                ) : (
                                                                    <CircularProgress style={{ width: 26, height: 26 }} />
                                                                )
                                                            }
                                                        </Button>
                                                    </Grid>
                                                    {openNotification &&
                                                        <Toast type="error" message={error} vertical="bottom" horizontal="center"></Toast>
                                                    }
                                                </Grid>
                                            </Paper>
                                        </form>
                                    </Grid>
                                ) :
                                    step === 3 ? (
                                        <Grid item xs={12} sm={12}>
                                            <Grid container direction="column" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Box className={classes.logo} component="div">
                                                        <Box component="img" src="/images/logo-login.svg" />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Alert severity="success" className="alert-text-center">Um e-mail foi enviado para o e-mail informado.<br />Siga as instruções contidas nele.</Alert>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Link to={"/login"} style={{ color: "#177CBF" }}>Voltar</Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : step === 4 ? (
                                        <Grid item xs={12} sm={12}>
                                            <Grid container direction="column" spacing={4}>
                                                <Grid item xs={12}>
                                                    <Box className={classes.logo} component="div">
                                                        <Box component="img" src="/images/logo-login.svg" />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Alert severity="success" className="alert-text-center">Sua senha foi alterada com sucesso!</Alert>
                                                </Grid>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Link to={"/login"} style={{ color: "#177CBF" }}>Fazer login</Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : null
                        }
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
export default RecuperarSenha;
