import { Box, CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import api from "../../services/api";
import "./style.css";

function LoadingMessage() {
	return (
		<div className="splash-screen">
			<CircularProgress style={{ width: 36, height: 36, color: "#52D1FE" }} />
		</div>
	);
}

function withSplashScreen(WrappedComponent) {
	return class extends Component {
		constructor(props) {
			super(props);
			this.state = {
				loading: true,
				redirect: { to: "" },
			};
		}

		async componentDidMount() {
			const location = window.location || {};
			if (location.href.match(/\/login|\/cadastre-se|\/recuperar-senha/)) {
				this.setState({
					...this.state,
					loading: false,
				});
			} else {
				try {
					let response = await api.get(`/user/ping`);
					setTimeout(() => {
						this.setState({
							...this.state,
							loading: false,
						});
					}, 2000);
				} catch (err) {
					console.log(err);
					setTimeout(() => {
						this.setState({
							...this.state,
							redirect: { to: "/login" },
						});
					}, 2000);
				}
			}
		}

		render() {
			if (this.state.redirect.to) {
				return <Redirect to={this.state.redirect.to} />;
			}
			// while checking user session, show "loading" message
			if (this.state.loading) return LoadingMessage();

			// otherwise, show the desired route
			return <WrappedComponent {...this.props} />;
		}
	};
}

export default withSplashScreen;
