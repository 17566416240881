import { CircularProgress, Divider, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
const DashboardComponent = ({
	xs = 12,
	md = 6,
	lg = 6,
	height = "60vh",
	title = "Title",
	loading = true,
	refresh = (e) => {
		e.preventDefault();
	},
	...props
}) => {
	return (
		<Grid item xs={xs} md={md} lg={lg} style={{ height: height }}>
			<Paper elevation={3}>
				<Grid container style={{ padding: 24 }}>
					<Grid container>
						<Grid item xs={10}>
							<strong>{title}</strong>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12}>
							{props.children}
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export default DashboardComponent;
