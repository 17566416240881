import api from "./api";

export const TOKEN_KEY = "@farmdev-token";
export const TYPE_KEY = "@farmdev-type";
export const ID_GROUP = "@farmdev-group-id";
export const NAME_GROUP = "@farmdev-group-name";
export const USER_KEY = "@farmdev-user";
export const SCOPE = "@farmdev-scope";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getScope = () => localStorage.getItem(SCOPE);

export const getUser = () => JSON.parse(localStorage.getItem(USER_KEY));
export const setUser = (user) => localStorage.setItem(USER_KEY, JSON.stringify(user));

export const getIdGroup = () => localStorage.getItem(ID_GROUP);
export const setIdGroup = (id_farm) => localStorage.setItem(ID_GROUP, id_farm);

export const setType = (type) => localStorage.setItem(TYPE_KEY, type);
export const getType = () => localStorage.getItem(TYPE_KEY);

export const setNameGroup = (name) => localStorage.setItem(NAME_GROUP, name);
export const getNameGroup = () => localStorage.getItem(NAME_GROUP);

export const login = data => {
  localStorage.setItem(TOKEN_KEY, data.token);
  // localStorage.setItem(ID_GROUP, data.id_farm);
  localStorage.setItem(SCOPE, data.scope);
  setUser(data);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(ID_GROUP);
  localStorage.removeItem(SCOPE);
};