import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from '../../components/Nav';
import { APP_NAME } from "../../config";
import { Backdrop, Box, Button, CircularProgress, Divider, Fab, FormControl, Grid, Hidden, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, withStyles, Zoom } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import api from "../../services/api";
import { Add, Search } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import SearchIcon from '@material-ui/icons/Search';


// import { Provider } from 'react-redux';
// import store from '../../store';

const classes = (theme) => ({
    toolbar: {
        ...theme.mixins.toolbar,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        [theme.breakpoints.up('md')]: {
            left: 240,
        },
        top: 90
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
});
class NoticiasList extends Component {
    state = {
        redirect: false,
        loading: true,
        noticias: [],
        search: { title: "" }
    }

    async componentDidMount() {
        this.props.setTitle("Notificacões");
        document.title = `${APP_NAME} - Notificacões`;
        await this.getData();
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect push to='/' />
        }
    }
    async getData() {
        try {
            const response = await api.get(`news`, { params: this.state.search });
            this.setState({
                ...this.state,
                noticias: response.data.data,
                loading: false
            });
        } catch (error) {
            if (error.response.status === 403) {
                this.setState({
                    redirect: true
                });
            }
        }
    }
    handleSearch = (e) => {
        this.setState({
            ...this.state,
            search: { title: e.target.value || "" }
        })
    }
    lista = () => {
        const { classes } = this.props;
        const { noticias } = this.state;

        if (!this.state.loading && noticias.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={2}>
                        <Alert severity="warning" className="alert-text-center">Nenhuma notificacão encontrada!</Alert>
                    </TableCell>
                </TableRow>
            )
        } else {

            return (
                noticias.map(noticia =>
                    <TableRow hover key={noticia.id}>
                        <TableCell>
                            <Link to={`/notificacoes/${noticia.id}/editar/`}>{noticia.title}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/notificacoes/${noticia.id}/editar/`}>{dayjs(noticia.event_date).format('DD/MM/YYYY HH:mm')}</Link>
                        </TableCell>
                    </TableRow>
                )
            )
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Hidden smUp>
                    <Grid container className="page-title" alignItems="center">
                        <Grid item sm={6} xs={6}>
                            <Typography variant="h5">Notificacões</Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={3} className="table-data">
                            <form onSubmit={(e) => { e.preventDefault(); this.getData() }}>
                                <Grid item xs={12} style={{ padding: "8px" }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="search"
                                        name="search"
                                        onChange={this.handleSearch}
                                        type="text"
                                        placeholder="Procurar"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton type="submit" style={{ padding: 10 }} aria-label="search">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </form>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Título
                                        </TableCell>
                                        <TableCell>
                                            Data
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.lista()}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Zoom
                            in={true}
                        >
                            <Tooltip title="Adicionar">
                                <Fab component={Link} to="/notificacoes/cadastro/novo" color="primary" className={classes.fab}>
                                    <Add htmlColor={"#FFF"}></Add>
                                </Fab>
                            </Tooltip>
                        </Zoom>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(classes)(NoticiasList);
