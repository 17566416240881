import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from '../../components/Nav';
import { APP_NAME, BUCKET_S3_IMAGE } from "../../config";
import { AppBar, Avatar, Backdrop, Button, CircularProgress, Fab, Grid, Hidden, IconButton, ListItem, ListItemText, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tooltip, Typography, withStyles, Zoom } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import api from "../../services/api";
import { getIdFarm, getUser } from "../../services/auth";
import { Add, Check, Delete, DeleteOutline, PlusOne, PlusOneOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import { withSnackbar } from 'notistack';
const classes = (theme) => ({
    toolbar: {
        minHeight: 54,
        [theme.breakpoints.up('sm')]: {
            minHeight: 54,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        [theme.breakpoints.up('md')]: {
            left: 240,
        },
        top: 90
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    tabs: {
        position: "absolute",
        top: 0,
        backgroundColor: theme.palette.primary.light
    }
});
class UsuarioList extends Component {
    state = {
        redirect: false,
        loading: true,
        users: [],
        usuariosAguardandoAprovacao: [],
        formTab: new URLSearchParams(window.location.search).get("tab") == "not-approved" ? 1 : 0,
    }
    componentDidMount() {
        document.title = `${APP_NAME} - Usuários`;
        this.props.setTitle("Usuários")
        this.getUsuarios();
        this.getUsuariosNaoAprovado();
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect push to='/' />
        }
    }
    getUsuarios() {
        api.get(`user/`, { params: { status: "ACTIVE" } })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        ...this.state,
                        redirect: false,
                        users: response.data.data,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 403) {
                    this.setState({
                        ...this.state,
                        redirect: true
                    });
                }
            });


    }
    getUsuariosNaoAprovado() {
        api.get(`user/not-approved`)
            .then((response) => {
                this.setState({
                    ...this.state,
                    usuariosAguardandoAprovacao: response.data.data,
                });
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status == 403) {
                    this.setState({
                        ...this.state,
                        redirect: true
                    });
                }
            });
    }
    lista = () => {
        const { classes } = this.props;
        let { users } = this.state;
        if (users.length) {
            users = users.filter(user => user.id !== getUser().id);
        }
        if (this.state.loading) {
            return (
                <Backdrop className={classes.backdrop} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
        } else if (users.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Alert severity="warning" className="alert-text-center">Nenhum usuário encontrado!</Alert>
                    </TableCell>
                </TableRow>
            )
        } else {
            users.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            return (
                users.map(user =>
                    <TableRow hover key={user.id}>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.name}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.email}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.scope}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{dayjs(user.date_create).format('DD/MM/YYYY HH:mm')}</Link>
                        </TableCell>
                    </TableRow>
                )
            )
        }
    }
    listaAguardandoAprovacao = () => {
        const { classes } = this.props;
        let users = this.state.usuariosAguardandoAprovacao;
        if (users.length) {
            users = users.filter(user => user.id !== getUser().id);
        }
        if (!this.state.loading &&users.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={6}>
                        <Alert severity="warning" className="alert-text-center">Nenhum usuário encontrado!</Alert>
                    </TableCell>
                </TableRow>
            )
        } else {
            users.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            return (
                users.map(user =>
                    <TableRow hover key={user.id}>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.name}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.email}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{user.scope}</Link>
                        </TableCell>
                        <TableCell>
                            <Link to={`/usuario/${user.id}/editar`}>{dayjs(user.date_create).format('DD/MM/YYYY HH:mm')}</Link>
                        </TableCell>
                        <TableCell>
                            {
                                user.loading ? (
                                    <CircularProgress style={{ width: 16, height: 16 }} />
                                ) : (
                                    <>
                                        <Tooltip title="Aprovar">
                                            <IconButton onClick={async () => await this.approve(user)}><Check></Check></IconButton>
                                        </Tooltip>
                                        <Tooltip title="Remover">
                                            <IconButton onClick={async () => await this.disapprove(user)}><Delete></Delete></IconButton>
                                        </Tooltip>
                                    </>
                                )
                            }

                        </TableCell>
                    </TableRow>
                )
            )
        }
    }
    approve = async (user) => {
        user.loading = true;
        this.setState({
            ...this.state,
            usuariosAguardandoAprovacao: this.state.usuariosAguardandoAprovacao,
        });
        try {
            let response = await api.put(`user/${user.id}/approve`);
            this.props.enqueueSnackbar("Usuário aprovado", { variant: "success", autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
            this.state.usuariosAguardandoAprovacao.splice(this.state.usuariosAguardandoAprovacao.indexOf(user), 1);
            this.setState({
                ...this.state,
                usuariosAguardandoAprovacao: this.state.usuariosAguardandoAprovacao,
            });
            this.getUsuarios();
        } catch (error) {
        }
    }
    disapprove = async (user) => {
        user.loading = true;
        this.setState({
            ...this.state,
            usuariosAguardandoAprovacao: this.state.usuariosAguardandoAprovacao,
        });
        try {
            let response = await api.delete(`user/${user.id}`);
            this.props.enqueueSnackbar("Usuário removido", { variant: "success", autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'center' } });
            this.state.usuariosAguardandoAprovacao.splice(this.state.usuariosAguardandoAprovacao.indexOf(user), 1);
            this.setState({
                ...this.state,
                usuariosAguardandoAprovacao: this.state.usuariosAguardandoAprovacao,
            });
        } catch (error) {
        }
    }
    handleTabChange = (event, newValue) => {
        this.setState({
            ...this.state,
            formTab: newValue
        })
    }
    render() {
        const { classes, novo } = this.props;
        const { formTab } = this.state;
        return (
            <>

                <Hidden smUp>
                    <Grid container className="page-title" alignItems="center">
                        <Grid item sm={6} xs={6}>
                            <Typography variant="h5">Usuários</Typography>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} elevation={3} className="table-data">
                            <AppBar position="relative" color="primary">
                                <Tabs value={formTab} variant="fullWidth" indicatorColor="secondary" onChange={this.handleTabChange}>
                                    <Tab label="Usuários" />
                                    <Tab label="Usuários aguardando aprovação" />
                                </Tabs>
                            </AppBar>
                            {
                                formTab == 0 ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Nome
                                                </TableCell>
                                                <TableCell>
                                                    E-mail
                                                </TableCell>
                                                <TableCell>
                                                    Perfil
                                                </TableCell>
                                                <TableCell>
                                                    Criado em
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.lista()}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Nome
                                                </TableCell>
                                                <TableCell>
                                                    E-mail
                                                </TableCell>
                                                <TableCell>
                                                    Perfil
                                                </TableCell>
                                                <TableCell>
                                                    Criado em
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.listaAguardandoAprovacao()}
                                        </TableBody>
                                    </Table>
                                )
                            }
                        </TableContainer>


                        <Zoom
                            in={true}
                        >
                            <Tooltip title="Adicionar">
                                <Fab component={Link} to="/usuario/cadastro/novo" color="primary" className={classes.fab}>
                                    <Add htmlColor={"#FFF"}></Add>
                                </Fab>
                            </Tooltip>
                        </Zoom>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(classes)(withSnackbar(UsuarioList));
