import React, { useEffect, useRef, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Nav from "../../components/Nav";
import { APP_NAME } from "../../config";
import { Grid, makeStyles, useTheme, List, ListItem, Badge, Box, Table, TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import { Link, Redirect, useParams } from "react-router-dom";
import api from "../../services/api";
import * as yup from "yup";
import { green, red } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";

import { useSnackbar } from "notistack";
import PercentColor from "../../utils/PercentColor";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Skeleton } from "@material-ui/lab";
import { Close, Delete } from "@material-ui/icons";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Bar, Chart, Doughnut, Line, defaults } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import Colors from "../../utils/Colors";
import DashboardComponent from "./dashboard";
Chart.register(zoomPlugin);
const useStyles = makeStyles((theme) => ({
	title: {
		color: "#1FD300",
		marginBottom: `${theme.spacing(2)}px`,
	},
	farmItem: {
		display: "flex",
		justifyContent: "space-between",
		cursor: "pointer",
		alignItems: "center",
		height: "80px",
		overflow: "hidden",
	},
	farmItemClickable: {
		"&:hover": {
			backgroundColor: "rgba(0,0,0,.05)",
		},
	},
}));
const Home = (props) => {
	dayjs.extend(isSameOrBefore);
	const { enqueueSnackbar } = useSnackbar();

	defaults.font = {
		...defaults.font,
		family: "Roboto, 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
		weight: 700,
	};
	const theme = useTheme();
	const classes = useStyles();
	const [data, setData] = useState({ loading: true });
	const [received, setReceived] = useState([]);
	const [dataChart, setDataChart] = useState({});
	let today = dayjs(new Date());
	let before = dayjs(today).subtract(15, "days");
	useEffect(() => {
		props.setTitle("Dashboard");
		document.title = `${APP_NAME} - Dashboard`;
		async function getData() {
			try {
				const response = await api.get(`/correspondence/statistics/`);
				setData({ ...response.data, loading: false });
			} catch (error) {
				enqueueSnackbar("Problemas ao acessar as estatísticas.", {
					variant: "error",
					autoHideDuration: 3000,
					anchorOrigin: { vertical: "top", horizontal: "right" },
				});
			}
			try {
				let dataBefore = dayjs(today).subtract(15, "days").startOf("day").toISOString();
				const response = await api.get(`/correspondence/?${encodeURI(`received_at[]=${dataBefore}`)}`);
				setReceived(response.data.data);
			} catch (error) {
				enqueueSnackbar("Problemas ao acessar as estatísticas.", {
					variant: "error",
					autoHideDuration: 3000,
					anchorOrigin: { vertical: "top", horizontal: "right" },
				});
			}
		}
		getData();
	}, []);

	const BadgeBox = ({ backgroundColor = "#F00", color = "#FFF", width = "100%", ...props }) => (
		<Box
			component="span"
			p={1}
			fontSize={"1.2rem"}
			textAlign="right"
			fontWeight="bold"
			width={width}
			bgcolor={backgroundColor}
			color={color}
			borderRadius={6}
			boxSizing="border-box"
			display={"inline-block"}
		>
			{props.children}
		</Box>
	);

	let width, height, gradient;
	function getGradient(ctx, chartArea) {
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (!gradient || width !== chartWidth || height !== chartHeight) {
			// Create the gradient because this is either the first render
			// or the size of the chart has changed
			width = chartWidth;
			height = chartHeight;
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, "#0b64b1");
			gradient.addColorStop(0.5, "#ffec03");
			gradient.addColorStop(1, "#bd2a2a");
		}

		return gradient;
	}
	const LineChart = () => {
		let labels = [];
		let datasets = [];
		while (before.isSameOrBefore(today, "date")) {
			labels.push(before.format("DD/MM"));
			datasets.push(received.filter((value) => dayjs(value.received_at).isSame(before, "date")).length);
			before = before.add(1, "day");
		}

		const dataChart = {
			labels: labels,
			datasets: [
				{
					label: "Quantidade Recebida",
					data: datasets,
					backgroundColor: "transparent",
					borderColor: function (context) {
						const chart = context.chart;
						const { ctx, chartArea } = chart;

						if (!chartArea) {
							// This case happens on initial chart load
							return;
						}
						return getGradient(ctx, chartArea);
					},
					tension: 0.1,
				},
			],
		};
		return (
			<Line
				data={dataChart}
				options={{
					plugins: { legend: false },
					responsive: true,
					aspectRatio: [1000 / 200],
					animations: {
						radius: {
							duration: 400,
							easing: "linear",
							loop: (context) => context.active,
						},
					},
					hoverRadius: 12,
					interaction: {
						mode: "nearest",
						intersect: false,
						axis: "x",
					},
					scale: {
						ticks: {
							precision: 0,
						},
					},
				}}
			/>
		);
	};

	return (
		<>
			<Grid container spacing={3}>
				<DashboardComponent title="Correspôndencias (TOTAL)" height="auto">
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<strong>Registradas</strong>
								</TableCell>
								<TableCell width={"100%"}>
									{data.loading ? (
										<Skeleton height={43.45} />
									) : (
										<BadgeBox backgroundColor="#625df5" width={data.total > 0 ? "100%" : "auto"}>
											{data.total}
										</BadgeBox>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<strong>Entregues</strong>
								</TableCell>
								<TableCell width={"100%"}>
									{data.loading ? (
										<Skeleton height={43.45} />
									) : (
										<BadgeBox
											backgroundColor="#08b980"
											width={(100 * data.total_picked) / data.total > 0 ? (100 * data.total_picked) / data.total + "%" : "auto"}
										>
											{data.total_picked}
										</BadgeBox>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</DashboardComponent>
				<DashboardComponent title="Correspôndencias (MÊS)" height="auto">
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<strong>Registradas</strong>
								</TableCell>
								<TableCell width={"100%"}>
									{data.loading ? (
										<Skeleton height={43.45} />
									) : (
										<BadgeBox backgroundColor="#625df5" width={data.total_month > 0 ? "100%" : "auto"}>
											{data.total_month}
										</BadgeBox>
									)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<strong>Entregues</strong>
								</TableCell>
								<TableCell width={"100%"}>
									{data.loading ? (
										<Skeleton height={43.45} />
									) : (
										<BadgeBox
											backgroundColor="#08b980"
											width={
												(100 * data.total_picked_month) / data.total_month > 0
													? (100 * data.total_picked_month) / data.total_month + "%"
													: "auto"
											}
										>
											{data.total_picked_month}
										</BadgeBox>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</DashboardComponent>
				<Grid item xs={12} style={{ textAlign: "center" }}>
					<strong>Correspondências recebidas nos ultimos 15 dias</strong>
					{LineChart()}
				</Grid>
			</Grid>
		</>
	);
};

export default Home;
