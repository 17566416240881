import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Box, Grid, Paper, Button, FormHelperText, withStyles, InputAdornment, TextField, Typography, CircularProgress } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import api from "../../services/api";
import { getIdFarm, login } from "../../services/auth";
import Toast from '../../components/Toast';
import { Link, Redirect } from 'react-router-dom';
import './styles.css';
import { APP_NAME } from "../../config";
import { blue, green } from "@material-ui/core/colors";
import { Lock, Person } from "@material-ui/icons";
const classes = theme => (
    {
        formLogin: {
            padding: theme.spacing(4),
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
            maxWidth: 320,
            margin: 'auto',
            backgroundColor: "#2E2E2E"
        },
        logo: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            marginBottom: "30px"
        },
        title: {
            color: "#707070"
        },
    }
);

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: "",
            response: {
                error: false,
                code: 0,
                message: ''
            },
            openNotification: false,
            disableButton: true,
            redirect: false,
            loading: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.requestLogin = this.requestLogin.bind(this);
    }
    componentDidMount() {
        document.title = `${APP_NAME} - Login`;
    }
    handleChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            ...this.state,
            [name]: value,
        });
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/' />
        }
    }
    requestLogin = async (event) => {
        event.preventDefault();
        const { email, password } = this.state;
        this.setState({ openNotification: false, loading: true });
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        api.post("/login", formData)
            .then((response) => {
                if (response.status === 200) {
                    login(response.data);
                    this.setState({ redirect: true, loading: false });
                }
            })
            .catch((error) => {
                this.setState({
                    error: 'E-mail ou senha inválido.',
                    openNotification: true,
                    loading: false,
                });
            });
    }
    render() {
        const { classes } = this.props;
        const { email, password, openNotification, error, loading } = this.state;
        let disableButton = !/\S+@\S+\.\S+/.test(email) || password.length < 6
        return (
            <React.Fragment>
                <CssBaseline />
                <Box component="div" className="login-page" /* style={{ backgroundImage: `url("/images/background-login.jpg")` }} */>
                    <Container>
                        <Grid container alignItems="center">
                            {/* <Hidden mdDown>
                                <Grid item xs={6} style={{ color: '#fff' }}>
                                    <Box component="h1" fontSize="h2.fontSize" fontWeight="fontWeightLight">
                                        Sua transportadora agora mais segura!
                                    </Box>
                                    <Box component="p">
                                        A <strong>{APP_NAME}</strong> ...
                                    </Box>
                                </Grid>
                            </Hidden> */}
                            <Grid item xs={12} sm={12}>
                                <form onSubmit={this.requestLogin}>
                                    <Paper elevation={0} className={classes.formLogin}>
                                        <Grid container direction="column" spacing={4}>
                                            <Grid item xs={12}>
                                                <Box className={classes.logo} component="div">
                                                    <Box component="img" src="/images/logo_atento.png" width={200} />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" align="center" className={classes.title}>Faça seu login</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel htmlFor="email">E-mail</InputLabel> */}
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        onChange={this.handleChange}
                                                        autoFocus={true}
                                                        autoComplete="username"
                                                        label="E-mail"
                                                        type="email"
                                                        className={classes.input}
                                                    /* InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Person />
                                                            </InputAdornment>
                                                        )
                                                    }} */
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel htmlFor="password">Senha</InputLabel> */}
                                                    <TextField
                                                        id="password"
                                                        name="password"
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleChange}
                                                        type="password"
                                                        autoComplete="current-password"
                                                        label="Senha"
                                                    /* InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Lock />
                                                            </InputAdornment>
                                                        )
                                                    }} */
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Button disabled={disableButton || loading} type="submit" size="large" variant="contained" color="primary" fullWidth>
                                                    {
                                                        !loading ? (
                                                            "Entrar"
                                                        ) : (
                                                            <CircularProgress style={{ width: 26, height: 26 }} />
                                                        )
                                                    }
                                                </Button>
                                                <Link to={"/recuperar-senha"} style={{ color: "#177CBF" }}>Esqueci senha</Link>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                <Link to={"/cadastre-se"} style={{ color: "#177CBF" }}>Cadastre-se</Link>
                                            </Grid>
                                            {openNotification &&
                                                <Toast type="error" message={error} vertical="bottom" horizontal="center"></Toast>
                                            }
                                            {this.renderRedirect()}
                                        </Grid>
                                    </Paper>
                                </form>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(classes)(Login);
