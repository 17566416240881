import React, { useEffect, useRef, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Nav from "../../components/Nav";
import { APP_NAME } from "../../config";
import {
	Backdrop,
	CircularProgress,
	FormControlLabel,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Switch,
	Button,
	Container,
	Hidden,
	Typography,
	Fab,
	Zoom,
	useTheme,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
} from "@material-ui/core";
import { Link, Redirect, useParams } from "react-router-dom";
import api from "../../services/api";
import InputMask from "react-input-mask";
import { Controller, useForm } from "react-hook-form";
import InputMasker from "../../components/InputMasker";
import { Add, Check, CloudUpload, Delete } from "@material-ui/icons";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validaCNPJ } from "../../utils/DocsValidations";
import { green, red } from "@material-ui/core/colors";

import { useSnackbar } from "notistack";
import ConfirmModal from "../../components/ConfirmModal";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		...theme.mixins.toolbar,
	},
	paperForm: {
		padding: theme.spacing(3),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
		color: theme.palette.common.white,
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[600],
		},
		zIndex: 2,
	},
	fabDelete: {
		position: "fixed",
		bottom: 100,
		right: theme.spacing(4),
		color: theme.palette.common.white,
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[600],
		},
		zIndex: 2,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#FFF",
		[theme.breakpoints.up("md")]: {
			left: 240,
		},
		top: 90,
	},
}));

yup.addMethod(yup.string, "identity", function (formats, parseStrict) {
	return this.test(`invalid-identity`, "CNPJ Inválido", function (value) {
		return validaCNPJ(value);
	});
});

const ManutencaoForm = (props) => {
	props.setTitle("Manutenções");
	const schema = yup.object().shape({
		title: yup.string().required("Campo Obrigatório"),
	});
	const methods = useForm({
		resolver: yupResolver(schema),
	});

	const theme = useTheme();

	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, control, reset, errors, register } = methods;
	const classes = useStyles();
	const [transparencia, setTransparencia] = useState({ id: 0, file: "" });
	const [fileName, setFileName] = useState("");
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [redirect, setRedirect] = useState({ to: "" });
	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};

	var { id } = useParams();
	id = parseInt(id) || 0;
	const handleOpenConfirm = () => {
		setOpenConfirm(true); // abre modal de confirmação
	};
	const closeOpenConfirm = () => {
		setOpenConfirm(false); // fecha modal de confirmação
	};
	const deleteTransparencia = (id) => {
		closeOpenConfirm();
		api.delete(`/transparence/${id}`)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar("Registro removido!", {
						variant: "success",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/manutencao/" });
				}
			})
			.catch((error) => {
				if (error.response.status !== 500) {
					enqueueSnackbar(error.response.data.msg, {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				}
			});
	};
	const changeFile = (event) => {
		if (event.target.files && event.target.files[0]) {
			if (event.target.files[0].size > 4194304) {
				enqueueSnackbar("Tamanho limite de 4mb excedido.", {
					variant: "error",
					autoHideDuration: 3000,
					anchorOrigin: { vertical: "top", horizontal: "right" },
				});
				event.target.value = "";
				setFileName("");
			} else {
				setFileName(event.target.files[0].name);
			}
		} else {
			event.target.value = "";
			setFileName("");
		}
	};
	const onSubmit = (data, event) => {
		setIsSaving(true);
		data.id = transparencia.id;
		let url = `/transparence`;
		if (data.id) url += `/${data.id}`;

		let formdata = new FormData(event.target);
		formdata.append("type", "MAINTENANCE");

		api({
			method: data.id ? "PUT" : "POST",
			data: formdata,
			url: url,
			headers: { "content-type": "multipart/form-data" },
		})
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar("Registro salvo!", {
						variant: "success",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/manutencao/" });
					setIsSaving(false);
				}
			})
			.catch((error) => {
				if (error.response.status != 500) {
					enqueueSnackbar(error.response.data.msg, {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				}
				setIsSaving(false);
			});
	};
	useEffect(() => {
		async function getData() {
			if (id) {
				try {
					const response = await api.get(`/transparence/${id}`);
					setTransparencia(response.data);
					reset({ ...response.data });
				} catch (error) {
					if (error.response.status === 403) {
						enqueueSnackbar("Você não tem permissão", {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
						setRedirect({ to: "/manutencao/" });
					}
					if (error.response.status === 404) {
						enqueueSnackbar("Registro não encontrada", {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
						setRedirect({ to: "/manutencao/" });
					}
				}
			}
			setLoading(false);
		}
		getData();
	}, []);

	if (redirect.to) {
		return <Redirect push to={redirect.to} />;
	}
	return (
		<>
			<Hidden smUp>
				<Grid container className="page-title" alignItems="center" justify="space-between">
					<Grid item xs={12}>
						<Typography variant="h5" display="block" noWrap>
							{id ? `${transparencia.title ? `${transparencia.title}` : ""}` : "Novo Registro"}
						</Typography>
					</Grid>
				</Grid>
			</Hidden>
			<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Paper elevation={3} className={classes.paperForm}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Controller
										as={TextField}
										error={errors.title ? true : false}
										helperText={errors.title?.message}
										fullWidth
										name="title"
										label="Título"
										control={control}
										variant="outlined"
										defaultValue=""
									/>
								</Grid>
								{id ? (
									<Grid item xs={12}>
										<Button href={transparencia.file} target="_blank" variant="contained">
											Abrir arquivo
										</Button>
									</Grid>
								) : null}
								<Grid item xs={12}>
									<input
										accept="application/pdf"
										id="file"
										multiple={false}
										type="file"
										name="file"
										style={{ display: "none" }}
										onChange={changeFile}
									/>
									<label htmlFor="file">
										<Button variant="contained" color="primary" component="span">
											{id ? "Subir novo arquivo" : "Subir arquivo"}
										</Button>
									</label>
									<span style={{ marginLeft: "1rem" }}>{fileName || "Nenhum arquivo selecionado"}</span>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
				<Zoom in={true} timeout={transitionDuration}>
					<Tooltip title="Salvar">
						<Fab type="submit" className={classes.fab} disabled={isSaving}>
							{!isSaving ? <Check></Check> : <CircularProgress style={{ width: 16, height: 16 }} />}
						</Fab>
					</Tooltip>
				</Zoom>
				{id ? (
					<Zoom in={true} timeout={transitionDuration}>
						<Tooltip title="Remover">
							<Fab onClick={() => handleOpenConfirm()} className={classes.fabDelete}>
								<Delete></Delete>
							</Fab>
						</Tooltip>
					</Zoom>
				) : null}
			</form>
			<ConfirmModal
				yes="Sim"
				no="Não"
				title="Remover"
				content="Deseja remover esse registro?"
				onCancel={closeOpenConfirm}
				onConfirm={() => deleteTransparencia(id)}
				open={openConfirm}
			/>
		</>
	);
};

export default ManutencaoForm;
