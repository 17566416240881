import { TextField } from '@material-ui/core';
import React from 'react';
import InputMask from 'react-input-mask';

export default class InputCNPJ extends React.Component {
    render() {
        return (
            <InputMask {...this.props}>
                {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
        )
    }
}