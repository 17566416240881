import React, { useEffect, useRef, useState } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from '../../components/Nav';
import { APP_NAME, BUCKET_S3_IMAGE } from "../../config";
import { Backdrop, CircularProgress, Grid, makeStyles, Paper, TextField, Hidden, Typography, Fab, Zoom, useTheme, Tooltip, MenuItem, Input, Button, Switch, FormControlLabel, Checkbox } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import api from "../../services/api";
import { Controller, useForm } from 'react-hook-form';
import InputMasker from '../../components/InputMasker'
import { Check, CloudUpload, Delete, PeopleAltOutlined } from "@material-ui/icons";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import { getId, getIdFarm, getScope, setUser } from "../../services/auth";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { validaCPF } from '../../utils/DocsValidations'
import { green, red } from '@material-ui/core/colors';

import { useSnackbar } from "notistack";
import ConfirmModal from "../../components/ConfirmModal";
import Estados from "../../utils/Estados";
import ReactHookFormSelect from "../../components/ReactHookFormSelect";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import './style.css';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        ...theme.mixins.toolbar,
    },
    paperForm: {
        padding: theme.spacing(3)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        [theme.breakpoints.up('md')]: {
            left: 240,
        },
        top: 90
    },
    fabDelete: {
        position: 'fixed',
        bottom: 100,
        right: theme.spacing(4),
        color: theme.palette.common.white,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600],
        },
    },
    avatarUpload: {
        width: 300,
        height: 300,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '50%',
        '&:hover': {
            '& span': {
                display: 'block'
            },
            'opacity': 0.5
        }
    }
}));

yup.addMethod(yup.string, 'identity', function (formats, parseStrict) {
    return this.test(`invalid-cpf`, 'CPF Inválido', function (value) {
        return validaCPF(value);
    });
});

const MinhaContaForm = (props) => {
    props.setTitle("Minha Conta")
    const [usuario, setUsuario] = useState({ id: 0, name: '' });
    const [enablePasswordChange, setEnablePasswordChange] = useState(false);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState({ to: '' });
    const [isSaving, setIsSaving] = useState(false);
    const schema = yup.object().shape({
        name: yup.string().required("Campo Obrigatório"),
        email: yup.string().required("Campo Obrigatório").email("E-mail inválido"),
        password: yup.string()
            .when([], {
                is: () => enablePasswordChange,
                then: yup.string()
                    .required("Informe sua senha")
                    .notOneOf(["Passw0rd", "Password123"], "Senha não pode ser Passw0rd ou Password123")
                    .min(8, "Senha deve conter no mínimo 8 letras")
                    .matches(/[a-z]/g, "Senha deve conter no mínimo 1 letra minúscula")
                    .matches(/[A-Z]/g, "Senha deve conter no mínimo 1 letra maiúscula")
                    .matches(/^[\S]+$/g, "Senha não pode conter espaço em branco"),
            }),
        confirm_password: yup.string()
            .when([], {
                is: () => enablePasswordChange,
                then: yup.string()
                    .required("Confirme sua senha")
                    .oneOf([yup.ref('password'), null], "Senhas não coincidem.")
            }),
    });
    const methods = useForm({
        resolver: yupResolver(schema)
    });

    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, control, reset, errors, register } = methods;
    const classes = useStyles();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };
    var { id } = useParams();
    id = parseInt(id) || 0;

    const onSubmit = (data, event) => {
        setIsSaving(true);
        data = { ...usuario, ...data };
        let url = `user/me`;
        api({
            method: "PUT",
            data: data,
            url: url,
            // headers: { 'content-type': 'multipart/form-data' }
        })
            .then(response => {
                if (response.status === 200) {
                    enqueueSnackbar("Conta atualizada!", { variant: "success", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setUser(response.data);
                    setRedirect({ to: '/' });
                }
            }).catch(error => {
                let msg = error.response.data.msg || "Erro inexperado";
                enqueueSnackbar(msg, { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                setIsSaving(false);
            })

    };

    useEffect(() => {
        async function fetchData() {
            let user = {};
            try {
                let response = await api.get(`/user/me`);
                user = response.data;
                setUsuario(response.data);
            } catch (error) {
                if (error.response.status === 403) {
                    enqueueSnackbar('Você não tem permissão', { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setRedirect({ to: '/usuario/' });
                }
                if (error.response.status === 404) {
                    enqueueSnackbar('Pessoa não encontrada', { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setRedirect({ to: '/usuario/' });
                }
            }
            reset({ ...user });
            setLoading(false);
        }
        fetchData();
    }, []);

    if (redirect.to) {
        return (<Redirect push to={redirect.to} />);
    }

    return (
        <>
            <Hidden smUp>
                <Grid container className="page-title" alignItems="center" justifyContent="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" noWrap>{id ? `${usuario.name ? `${usuario.name}` : ''}` : "Novo Usuário"}</Typography>
                    </Grid>
                </Grid>
            </Hidden>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paperForm}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Controller as={TextField} error={errors.name ? true : false} helperText={errors.name?.message} fullWidth name="name" label="Nome" control={control} variant="outlined" defaultValue="" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller as={TextField} error={errors.email ? true : false} helperText={errors.email?.message} fullWidth name="email" label="E-mail" control={control} variant="outlined" defaultValue="" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name="change_password"
                                                control={control}
                                                color="primary"
                                                defaultValue={false}
                                                render={props =>
                                                    <Switch
                                                        checked={props.value}
                                                        onChange={e => { props.onChange(e.target.checked); setEnablePasswordChange(e.target.checked) }}
                                                        color="primary"
                                                        label="Alterar senha"
                                                    />
                                                }
                                            />
                                        }
                                        label="Alterar senha"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Controller disabled={!enablePasswordChange} type="password" as={TextField} error={errors.password ? true : false} helperText={errors.password?.message} fullWidth name="password" label="Senha" control={control} variant="outlined" defaultValue="123456" value="" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller disabled={!enablePasswordChange} type="password" as={TextField} error={errors.confirm_password ? true : false} helperText={errors.confirm_password?.message} fullWidth name="confirm_password" label="Confirme a senha" control={control} variant="outlined" defaultValue="123456" />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Zoom
                    in={true}
                    timeout={transitionDuration}
                >
                    <Tooltip title="Salvar">
                        <Fab type="submit" className={classes.fab} disabled={isSaving}>
                            {
                                !isSaving ? (
                                    <Check></Check>
                                ) : (
                                    <CircularProgress style={{ width: 16, height: 16 }} />
                                )
                            }
                        </Fab>
                    </Tooltip>
                </Zoom>
            </form>
        </>
    )
}



export default MinhaContaForm;
