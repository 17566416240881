import { SvgIcon } from "@material-ui/core";
import { Component } from "react";

export class FarmIcon extends Component {
  render() {
    return (
      <SvgIcon {...this.props}>
         <path fill="currentColor" d="M12,3L3,8.2V21H9L11.9,18L15,21H21V8.2L12,3M7.9,20V14L10.9,17L7.9,20M8.9,13H14.9L11.9,16L8.9,13M15.9,20L12.9,17L15.9,14V20M15,11H8.8V9H15V11Z" />
      </SvgIcon>
    );
  }
}