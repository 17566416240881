import axios from 'axios';
import { getToken } from './auth';
import { API_URL } from '../config';
const api = axios.create({ baseURL: API_URL });
api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.common['x-access-token'] = token;
    }
    return config;
});

api.interceptors.response.use((response) => response, (error) => {
    if (!error.response) {
        if (error.message === "Network Error") {
            // alert("Sistema offline. Senão retornar em alguns minutos, contate o suporte.");
        } else {
            alert("Falha na conexão. Erro: " + error.message);
        }
    } else if (error.response.status === 401 && error.response.config.url !== "/login") {
        window.location.href = window.location.origin + '/login';
    }
    throw error;
});

export default api;