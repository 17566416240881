import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Badge, Box, Hidden, makeStyles, MenuItem, Tooltip, CircularProgress, Menu, Grid, Typography, Divider } from "@material-ui/core";
import { GroupAdd } from "@material-ui/icons";
import NavMenu from "./menu";
import api from "../../services/api";
import dayjs from "dayjs";
import { blue, deepPurple } from "@material-ui/core/colors";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: "#2E2E2E",
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	title: {
		[theme.breakpoints.up("sm")]: {
			display: "flex",
			alignItems: "center"
		},
		color: "inherit"
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			marginLeft: 252,
			width: "calc(100% - 252px)",
		},
		backgroundColor: "transparent",
		color: "#2E2E2E"
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: theme.palette.primary.main
	},
	sectionDesktop: {
		display: "none",
		color: "#FFF",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	sectionMobile: {
		display: "flex",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
	iconColor: {
		color: "inherit",
	},
	toolbar: {
		...theme.mixins.toolbar,
		display: 'flex',
		alignItems: 'center',
	},
	menuNotification: {
		maxHeight: 400,
		overflow: "auto",
	},
	notificationItem: {
		fontSize: "0.8rem"
	}
}));
const Header = (props) => {
	// const container = window !== undefined ? () => window.document.body : undefined;
	const classes = useStyles();
	const [menuMobile, setMenuMobile] = React.useState(false);
	const [countNotification, setCountNotification] = React.useState(0);
	const [notificationLoading, setNotificationLoading] = React.useState(true);
	const [notifications, setNotifications] = React.useState([]);
	const openMenu = (value) => {
		setMenuMobile(value);
	}

	useEffect(() => {
		const interval = setInterval(async () => {
			try {
				let response = await api.get(`/notification/count`);
				setCountNotification(response.data.count || 0);
			} catch (error) { }
		}, 10 * 1000);
		return () => clearInterval(interval);
	}, []);

	const getTime = (created_at) => {
		if (dayjs(new Date()).diff(dayjs(created_at), 'years')) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'year');
			return `há ${diff} ${diff > 1 ? "anos" : "ano"}`;
		}
		if (dayjs(new Date()).diff(dayjs(created_at), 'months')) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'month');
			return `há ${diff} ${diff > 1 ? "meses" : "mês"}`;
		}
		if (dayjs(new Date()).diff(dayjs(created_at), 'days')) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'day');
			return `há ${diff} ${diff > 1 ? "dias" : "dia"}`;
		}
		if (dayjs(new Date()).diff(dayjs(created_at), 'hours')) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'hours');
			return `há ${diff} ${diff > 1 ? "horas" : "hora"}`;
		}
		if (dayjs(new Date()).diff(dayjs(created_at), 'minutes')) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'minutes');
			return `há ${diff} ${diff > 1 ? "minutos" : "minuto"}`;
		}
		if (dayjs(new Date()).diff(dayjs(created_at), "seconds")) {
			let diff = dayjs(new Date()).diff(dayjs(created_at), 'seconds');
			return `há ${diff} ${diff > 1 ? "segundos" : "segundo"}`;
		}
	}
	const [anchorMenu, setAnchorMenu] = React.useState(null);

	const closeMenuNotification = () => {
		setAnchorMenu(null);
		setNotifications([]);
		setNotificationLoading(true);
	};
	const openMenuNotification = async (event) => {
		setAnchorMenu(event.currentTarget);
		await getNotifications();
	}
	const getNotifications = async () => {
		try {
			let response = await api.get(`/notification/unread`);
			setNotificationLoading(false);
			setNotifications(response.data || []);
		} catch (error) {
			setNotifications([]);
		}
	}
	const updateNotification = async (notification) => {
		closeMenuNotification();
		setCountNotification(countNotification - 1);
		try {
			let response = await api.put(`/notification/${notification.id}`);
		} catch (error) {
		}
	}
	const getNotificationType = (notification) => {
		if (notification.type == "USER_WAITING_APPROVE") {
			return (
				<MenuItem onClick={() => updateNotification(notification)} style={{ width: 320, whiteSpace: "unset" }}>
					<Link to={`/usuario/?tab=not-approved`}>
						<Grid container alignItems="center">
							<Grid item xs={1}>
								<Box textAlign="center">
									<GroupAdd style={{ fontSize: "1rem" }} />
								</Box>
							</Grid>
							<Grid item xs={11} className={classes.notificationItem}>
								<Box style={{ fontSize: "0.6rem", color: "rgba(0,0,0,.5)" }}>{getTime(notification.date_create)}</Box>
								<Box dangerouslySetInnerHTML={{ __html: notification.message.html }}></Box>
							</Grid>
						</Grid>
					</Link>
				</MenuItem>
			)
		}
	}
	return (
		<React.Fragment>
			<AppBar position="relative" className={classes.appBar} elevation={0}>
				<Toolbar >
					<Hidden smUp implementation="css">
						<IconButton edge="start" color="" aria-label="open drawer" onClick={() => setMenuMobile(true)} >
							<MenuIcon />
						</IconButton>
					</Hidden>
					{/* {
						props.back ?
							(
								<Hidden smDown>
									<Link to={props.back} tabIndex={-1} className={classes.iconColor}>
										<IconButton aria-label="Voltar" color="inherit" tabIndex={0}>
											<ArrowBack />
										</IconButton>
									</Link>
								</Hidden>
							) : null
					} */}

					<Typography className={classes.title} variant="h6" noWrap align="center">
						{props.title}
					</Typography>
					<div className={classes.grow} />
					<Tooltip title="Notificações">
						<IconButton aria-label="Notificações" color="inherit" tabIndex={0} onClick={openMenuNotification}>
							<Badge badgeContent={countNotification} color="error">
								<NotificationsIcon />
							</Badge>
						</IconButton>
					</Tooltip>
					{/* <Link to="/minha-conta" tabIndex={-1} className={classes.iconColor}>
						<Tooltip title="Minha Conta">
							<IconButton edge="end" aria-label="Minha Conta" color="inherit" tabIndex={0}>
							<AccountCircle />
							</IconButton>
							</Tooltip>
						</Link> */}
				</Toolbar>
				<Divider style={{marginLeft:'16px',marginRight:'16px'}} />
			</AppBar>
			<NavMenu
				open={menuMobile}
				setOpen={openMenu}
			/>
			<Menu
				id="menu-list-notification"
				anchorEl={anchorMenu}
				keepMounted
				open={Boolean(anchorMenu)}
				onClose={closeMenuNotification}
				getContentAnchorEl={null}
				className={classes.menuNotification}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{
					notificationLoading ? (
						<MenuItem button={false} onClick={closeMenuNotification} style={{ textAlign: "center", width: 320 }}><CircularProgress style={{ width: 16, height: 16, margin: "auto" }} /></MenuItem>
					) : (
						notifications.length ? (
							notifications.map(notification => getNotificationType(notification))
						) : (
							<MenuItem button={false} style={{ width: 320, whiteSpace: "unset" }}>
								<Grid container alignItems="center">
									<Grid item xs={12} className={classes.notificationItem}>
										<Box>Nenhuma notificação.</Box>
									</Grid>
								</Grid>
							</MenuItem>
						)
					)
				}
			</Menu>
		</React.Fragment>
	);
}
export default Header;
