import React, { Component, useEffect, useState } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Box, Grid, Paper, Button, ListItem, List, CircularProgress, ListItemText, withStyles, Typography, TextField, InputAdornment, Divider, makeStyles, Checkbox, MenuItem } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import api from "../../services/api";
import { getScope, setIdFarm, setNameFarm, setType } from "../../services/auth";
import { Link, Redirect } from 'react-router-dom';
import './styles.css';
import { APP_NAME } from "../../config";
import { SearchRounded } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { validaCPF } from "../../utils/DocsValidations";
import InputMasker from '../../components/InputMasker'
import ReactHookFormSelect from "../../components/ReactHookFormSelect";
import ControlledAutocomplete from "../../components/ControlledAutocomplete";

const useStyles = makeStyles((theme) => ({
    formLogin: {
        padding: theme.spacing(4),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        maxWidth: 320,
        margin: 'auto',
    },
    title: {
        color: "#1FD300"
    },
    maxHeight: {
        maxHeight: 288,
        overflowY: 'auto'
    },
    search: {
        paddingLeft: `${theme.spacing(2)}px`,
        paddingRight: `${theme.spacing(2)}px`,
        color: "#1FD300",
        textAlign: "center"
    },
    center: {
        margin: `${theme.spacing(2)}px`,
        textAlign: 'center'
    },
    loginButton: {
        // f79036
        backgroundColor: "#1FD300",
        '&:hover': {
            backgroundColor: green[500],
        },
        color: '#FFF'
    },
}));

const CadastreSe = () => {
    const classes = useStyles();
    const [condominios, setCondominios] = useState({});
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    yup.addMethod(yup.string, 'document', function (formats, parseStrict) {
        return this.test(`invalid-cpf`, 'CPF Inválido', function (value) {
            return validaCPF(value);
        });
    });

    const schema = yup.object().shape({
        name: yup.string().required("Campo Obrigatório"),
        email: yup.string().required("Campo Obrigatório").email("E-mail inválido"),
        condominium: yup.object().nullable().required("Campo Obrigatório"),
        password: yup.string()
            .required("Informe sua senha")
            .notOneOf(["Passw0rd", "Password123"], "Senha não pode ser Passw0rd ou Password123")
            .min(8, "Senha deve conter no mínimo 8 letras")
            .matches(/[a-z]/g, "Senha deve conter no mínimo 1 letra minúscula")
            .matches(/[A-Z]/g, "Senha deve conter no mínimo 1 letra maiúscula")
            .matches(/^[\S]+$/g, "Senha não pode conter espaço em branco"),
        confirm_password: yup.string().required("Confirme sua senha")
            .oneOf([yup.ref('password'), null], "Senhas não coincidem."),
        document: yup.string().document("CPF Inválido"),
    });
    const methods = useForm({
        resolver: yupResolver(schema)
    });
    const { handleSubmit, control, reset, errors, register } = methods;
    useEffect(() => {
        document.title = `${APP_NAME} - Cadastrar`;
        setIsSaving(false);
        async function fetchData() {
            try {
                let response = await api.get(`register/condominium`);
                setCondominios(response.data.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    const onSubmit = async (data, event) => {
        console.log(data)
        setIsSaving(true);
        let formdata = new FormData(event.target);
        formdata.append("id_condominium", data.condominium.id);
        try {
            let response = await api.post(`/register`, formdata);
            setSuccess(true);
        } catch (error) {
            setErrorMessage(error.response.data);
        }
        setIsSaving(false);
    };

    return (
        <>
            <CssBaseline />
            <Box component="div" className="login-page">
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={12}>
                            <Paper elevation={6} className={classes.formLogin}>
                                <Grid container direction="column" spacing={4}>
                                    {
                                        success ?
                                            <>
                                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                                    <Alert severity="success">Cadastro efetuado com sucesso. Aguarde um administrador aprovar o seus dados.</Alert>
                                                    <Link to={"/login"} style={{ color: "#177CBF" }}>Voltar</Link>
                                                </Grid>
                                            </>
                                            : (
                                                <>
                                                    {
                                                        loading ?
                                                            <Box display="flex" justifyContent="center">
                                                                <CircularProgress />
                                                            </Box> : null
                                                    }

                                                    {
                                                        !loading && !condominios.length ?
                                                            <Alert severity="error">Nenhum condominio encontrado.</Alert> : null
                                                    }

                                                    {
                                                        !loading && condominios.length ?
                                                            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <Grid container spacing={3}>
                                                                            <Grid item xs={12}>
                                                                                <Controller as={TextField} error={errors.name ? true : false} helperText={errors.name?.message} fullWidth name="name" label="Nome" control={control} variant="outlined" defaultValue="" />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Controller as={TextField} error={errors.email ? true : false} helperText={errors.email?.message} fullWidth name="email" label="E-mail" control={control} variant="outlined" defaultValue="" />
                                                                            </Grid>
                                                                             <Grid item xs={12}>
                                                                                <Controller as={InputMasker} mask="999.999.999-99" error={errors.document ? true : false} helperText={errors.document?.message} fullWidth name="document" label="CPF" control={control} variant="outlined" defaultValue="" />
                                                                            </Grid>
                                                                            {/*<Grid item xs={12}>
                                                                                <Controller as={InputMasker} mask="99.999.999/9999-99" error={errors.employer_number ? true : false} helperText={errors.employer_number?.message} fullWidth name="employer_number" label="CNPJ" control={control} variant="outlined" defaultValue="" />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Controller as={InputMasker} mask="99.999.999-9" error={errors.identity_2 ? true : false} helperText={errors.identity_2?.message} fullWidth name="identity_2" label="RG" control={control} variant="outlined" defaultValue="" />
                                                                            </Grid> */}
                                                                            <Grid item xs={12}>
                                                                                <Controller type="password" as={TextField} error={errors.password ? true : false} helperText={errors.password?.message} fullWidth name="password" label="Senha" control={control} variant="outlined" value="" />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Controller type="password" as={TextField} error={errors.confirm_password ? true : false} helperText={errors.confirm_password?.message} fullWidth name="confirm_password" label="Confirme a senha" control={control} variant="outlined" />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <ControlledAutocomplete
                                                                                    openText="Procurar"
                                                                                    clearText="Limpar"
                                                                                    options={condominios}
                                                                                    getOptionLabel={(condominio) => `${condominio.name}`}
                                                                                    renderInput={(params) => <TextField {...params} label="Selecione o Condôminio" helperText={errors.condominium?.message}
                                                                                        error={errors.condominium ? true : false} variant="outlined" inputProps={{
                                                                                            ...params.inputProps,
                                                                                            autoComplete: "disabled" // disable autocomplete and autofill
                                                                                        }} />}
                                                                                    noOptionsText="Nenhum resultado"
                                                                                    fullWidth
                                                                                    control={control}
                                                                                    name="condominium"
                                                                                    defaultValue={null}

                                                                                    onChange={([event, data]) => {
                                                                                        return data;
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            {/* <Grid item xs={6}>
                                                                                <input
                                                                                    accept="image/*"
                                                                                    id="photo_file"
                                                                                    type="file"
                                                                                    name="photo"
                                                                                    multiple={false}
                                                                                    style={{ display: "none" }}
                                                                                />
                                                                                <label htmlFor="photo_file">
                                                                                    <Button variant="contained" color="primary" component="span" fullWidth>
                                                                                        Foto
                                                                                    </Button>
                                                                                </label>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <input
                                                                                    // accept="image/*"
                                                                                    id="document_file"
                                                                                    type="file"
                                                                                    name="document_url"
                                                                                    multiple={false}
                                                                                    style={{ display: "none" }}
                                                                                />
                                                                                <label htmlFor="document_file">
                                                                                    <Button variant="contained" color="primary" component="span" fullWidth>
                                                                                        Documento
                                                                                    </Button>
                                                                                </label>
                                                                            </Grid> */}
                                                                            <Grid item xs={12}>
                                                                                <Button type="submit" disabled={isSaving} size="large" variant="contained" color="primary" fullWidth>
                                                                                    {
                                                                                        isSaving ?
                                                                                            <CircularProgress style={{ width: 26, height: 26 }} /> :
                                                                                            "Cadastrar"
                                                                                    }
                                                                                </Button>
                                                                            </Grid>
                                                                            {
                                                                                errorMessage !== false ? (
                                                                                    <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                                        <Alert severity="error">{errorMessage.msg}</Alert>
                                                                                    </Grid>
                                                                                ) : null
                                                                            }

                                                                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                                                                <Link to={"/login"} style={{ color: "#177CBF" }}>Voltar</Link>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </form> : null
                                                    }
                                                </>
                                            )
                                    }

                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default CadastreSe;
