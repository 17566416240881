import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect, Router } from 'react-router-dom';
import { getScope, isAuthenticated } from "./services/auth";
import Login from './pages/login';
import RecuperarSenha from './pages/recuperar-senha';
import CadastreSe from './pages/cadastre-se';
import Home from './pages/home';
import CorrespondenciaList from './pages/correspondencia/list';
import NoticiasList from './pages/noticias/list';
import EventosList from './pages/eventos/list';
import UsuarioList from './pages/usuario/list';
import TransparenciaList from './pages/transparencia/list';
import ManutencaoList from './pages/manutencao/list';
import UsuarioForm from './pages/usuario/form';
import MinhaContaForm from './pages/minha-conta/form';

import { SnackbarProvider } from 'notistack';
import Nav from './components/Nav';
import CorrespondenciaForm from './pages/correspondencia/form';
import NoticiaForm from './pages/noticias/form';
import EventoForm from './pages/eventos/form';
import TransparenciaForm from './pages/transparencia/form';
import ManutencaoForm from './pages/manutencao/form';


const UserRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
            )
        }
    />
);

const AdminRoute = ({ component: Component, ...rest }) => (

    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                getScope() === "ADMINISTRATOR" ? (
                    <Component {...props} />
                )
                    :
                    (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    )
            ) :
                (
                    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                )
        }
    />
);

const RouterWrapper = () => {
    const [title, setTitle] = useState("");
    return (<div className="main-app">
        <Nav title={title} />
        <main className="main-content">
            <UserRoute path="/" strict={true} exact={true} >
                <Home setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/minha-conta" exact={true} >
                <MinhaContaForm setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/correspondencia" exact={true} >
                <CorrespondenciaList setTitle={setTitle} />
            </UserRoute>
            <UserRoute path={["/correspondencia/:id/editar", "/correspondencia/cadastro/novo"]} exact={true} >
                <CorrespondenciaForm setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/notificacoes" exact={true} >
                <NoticiasList setTitle={setTitle} />
            </UserRoute>
            <UserRoute path={["/notificacoes/:id/editar", "/notificacoes/cadastro/novo"]} exact={true} >
                <NoticiaForm setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/eventos" exact={true} >
                <EventosList setTitle={setTitle} />
            </UserRoute>
            <UserRoute path={["/eventos/:id/editar", "/eventos/cadastro/novo"]} exact={true} >
                <EventoForm setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/transparencia" exact={true} >
                <TransparenciaList setTitle={setTitle} />
            </UserRoute>
            <UserRoute path={["/transparencia/:id/editar", "/transparencia/cadastro/novo"]} exact={true} >
                <TransparenciaForm setTitle={setTitle} />
            </UserRoute>
            <UserRoute path="/manutencao" exact={true} >
                <ManutencaoList setTitle={setTitle} />
            </UserRoute>
            <UserRoute path={["/manutencao/:id/editar", "/manutencao/cadastro/novo"]} exact={true} >
                <ManutencaoForm setTitle={setTitle} />
            </UserRoute>
            <AdminRoute path="/usuario" exact={true} >
                <UsuarioList setTitle={setTitle} />
            </AdminRoute>
            <AdminRoute path={["/usuario/:id/editar", "/usuario/cadastro/novo"]} exact={true} >
                <UsuarioForm setTitle={setTitle} />
            </AdminRoute>
        </main>
    </div>)
}

const Routes = () => (
    <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/recuperar-senha">
                    <RecuperarSenha />
                </Route>
                <UserRoute path="/cadastre-se" exact={true}>
                    <CadastreSe />
                </UserRoute>
                <Route>
                    <RouterWrapper />
                </Route>
            </Switch>
        </BrowserRouter>
    </SnackbarProvider>
)

export default Routes;