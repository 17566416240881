import React, { useEffect, useState } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';

import Nav from '../../components/Nav';
import { Backdrop, CircularProgress, Grid, makeStyles, Paper, TextField, Hidden, Typography, Fab, Zoom, useTheme, Tooltip, FormControlLabel, Switch, MenuItem, FormControl, TextareaAutosize } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import api from "../../services/api";
import { Controller, useForm } from 'react-hook-form';
import { Check, Delete } from "@material-ui/icons";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { green, red } from '@material-ui/core/colors';

import { useSnackbar } from "notistack";
import ConfirmModal from "../../components/ConfirmModal";
import ReactHookFormSelect from "../../components/ReactHookFormSelect";
import Estados from "../../utils/Estados";
import InputMasker from '../../components/InputMasker'
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import dayjs from "dayjs";
import ControlledAutocomplete from "../../components/ControlledAutocomplete";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        ...theme.mixins.toolbar,
    },
    paperForm: {
        padding: theme.spacing(3)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        color: theme.palette.common.white,
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[600],
        },
    },
    fabDelete: {
        position: 'fixed',
        bottom: 100,
        right: theme.spacing(4),
        color: theme.palette.common.white,
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[600],
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#FFF',
        [theme.breakpoints.up('md')]: {
            left: 240,
        },
        top: 90
    },
}));


const NoticiaForm = (props) => {
    props.setTitle("Notificações");

    var { id } = useParams();
    id = parseInt(id) || 0;
    const [data, setData] = useState({
        noticia: { id: 0 },
    });
    const [moradores, setMoradores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [redirect, setRedirect] = useState({ to: '' });
    const schema = yup.object().shape({
        title: yup.string().required("Campo Obrigatório"),
        content: yup.string().required("Campo Obrigatório"),
        event_date: yup.date().required("Campo Obrigatório"),
    });
    const methods = useForm({
        resolver: yupResolver(schema)
    });

    const theme = useTheme();

    const { enqueueSnackbar } = useSnackbar();

    const { handleSubmit, control, reset, errors } = methods;
    const classes = useStyles();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };
    const handleOpenConfirm = () => {
        setOpenConfirm(true); // abre modal de confirmação
    }
    const closeOpenConfirm = () => {
        setOpenConfirm(false); // fecha modal de confirmação
    }
    const deleteNoticia = (id) => {
        closeOpenConfirm();
        api.delete(`/news/${id}`).then(response => {
            if (response.status === 200) {
                enqueueSnackbar("Notificação removida!", { variant: "success", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                setRedirect({ to: '/notificacoes/' });
            }
        }).catch(error => {
            if (error.response.status !== 500) {
                enqueueSnackbar(error.response.data.msg, { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
        })
    }
    const onSubmit = (_data) => {
        setIsSaving(true);
        _data = { ...data.noticia, ..._data };

        let url = `/news`;
        if (_data.id)
            url += `/${_data.id}`

        api({
            method: _data.id ? 'PUT' : 'POST',
            data: _data,
            url: url
        })
            .then(response => {
                if (response.status === 200) {
                    enqueueSnackbar("Notificacão salva!", { variant: "success", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setRedirect({ to: '/notificacoes/' });
                }
                setIsSaving(false);
            }).catch(error => {
                if (error.response.status !== 500) {
                    enqueueSnackbar(error.response.data.msg, { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
                setIsSaving(false);
            })
    };
    useEffect(() => {
        async function getData() {
            if (id) {
                try {
                    const response = await api.get(`/news/${id}`);
                    let data = response.data;
                    data.event_date = dayjs(data.event_date).format("YYYY-MM-DD HH:mm")
                    setData({
                        ...data,
                        noticia: response.data,
                    })
                    reset({ ...response.data });
                } catch (error) {
                    if (error.response.status === 403) {
                        enqueueSnackbar('Você não tem permissão', { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        setRedirect({ to: '/notificacoes/' });
                    }
                    if (error.response.status === 404) {
                        enqueueSnackbar('Notificação não encontrada', { variant: "error", autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                        setRedirect({ to: '/notificacoes/' });
                    }
                }

            }
            setLoading(false);
        }
        getData();
    }, []);

    if (redirect.to) {
        return (<Redirect push to={redirect.to} />);
    }
    return (
        <>
            <Hidden smUp>
                <Grid container className="page-title" alignItems="center" justify="space-between">
                    <Grid item xs={12}>
                        <Typography variant="h5" display="block" noWrap>{id ? `${data.noticia.title ? `${data.noticia.title}`.trim() : ''}` : "Nova Notificação"}</Typography>
                    </Grid>
                </Grid>
            </Hidden>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper elevation={3} className={classes.paperForm}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Controller as={TextField} fullWidth name="title" label="Título" error={errors.title ? true : false} helperText={errors.title?.message} control={control} variant="outlined" defaultValue="" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller as={TextField} multiline={true} fullWidth name="content" label="Conteúdo" error={errors.content ? true : false} helperText={errors.content?.message} control={control} variant="outlined" defaultValue="" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller as={TextField} type="datetime-local" fullWidth name="event_date" label="Aparecer até" error={errors.event_date ? true : false} helperText={errors.event_date?.message} control={control} variant="outlined" defaultValue={dayjs(data.noticia.event_date).format("YYYY-MM-DD HH:mm") || dayjs(new Date()).format("YYYY-MM-DD HH:mm")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Zoom
                    in={true}
                    timeout={transitionDuration}
                >
                    <Tooltip title="Salvar">
                        <Fab type="submit" className={classes.fab}>
                            {
                                !isSaving ? (
                                    <Check></Check>
                                ) : (
                                    <CircularProgress style={{ width: 16, height: 16 }} />
                                )
                            }
                        </Fab>
                    </Tooltip>
                </Zoom>
                {
                    id ? (
                        <Zoom
                            in={true}
                            timeout={transitionDuration}
                        >
                            <Tooltip title="Remover">
                                <Fab onClick={() => handleOpenConfirm()} className={classes.fabDelete}>
                                    <Delete></Delete>
                                </Fab>
                            </Tooltip>
                        </Zoom>
                    ) : null
                }
            </form>
            <ConfirmModal
                yes="Sim"
                no="Não"
                title="Remover"
                content="Deseja remover essa notificação?"
                onCancel={closeOpenConfirm}
                onConfirm={() => deleteNoticia(id)}
                open={openConfirm}
            />
        </>
    )
}



export default NoticiaForm;
