import { FormControl, FormHelperText, InputLabel, Select } from "@material-ui/core";
import { Controller } from "react-hook-form";

const ReactHookFormSelect = ({
    name,
    label,
    control,
    defaultValue,
    children,
    helperText,
    error,
    handleChange,
    ...props
}) => {
    const labelId = `${name}-label`;
    return (
        <FormControl {...props}>
            <InputLabel id={labelId} error={error}>{label}</InputLabel>
            <Controller
                render={({ onChange, value, onBlur, name }) => (
                    <Select
                        labelId={labelId}
                        label={label}
                        onChange={(e) => {
                            onChange(e);
                            try {
                                handleChange(e)
                            } catch (error) { }
                        }}
                        value={value || ""}
                    >
                        {children}
                    </Select>
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                error={error}
            />
            {error ? (<FormHelperText error={error}>{helperText}</FormHelperText>) : null}
        </FormControl>
    );
};
export default ReactHookFormSelect;