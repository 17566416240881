import React from 'react';
import Routes from './routes';
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import SplashScreen from './components/SplashScreen';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, blueGrey, deepPurple, orange, red } from '@material-ui/core/colors';

const theme = createTheme({
	typography: {
		fontFamily: [
			'Roboto',
		].join(','),
	},
	palette: {
		primary: {
			main: "#52D1FE",
			light: blueGrey[800],
			dark: '#002884',
			contrastText: '#fff',
		},
		secondary: {
			light: '#ff7961',
			main: blue[500],
			dark: '#ba000d',
			contrastText: '#fff',
		},
	},
});

const App = () => (
	<ThemeProvider theme={theme}>
		<Routes />
	</ThemeProvider>
)

// export default App;
export default SplashScreen(App);