import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Avatar, Box, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { AccountCircleRounded, Build, Dashboard, DynamicFeed, EventSharp, ExitToApp, Feedback, Home, LocalShipping, MonetizationOn, MonetizationOnOutlined, Money, NewReleases, Pages, PanTool, PeopleSharp, Timeline } from "@material-ui/icons";
import { FarmIcon } from "../../utils/icons/FarmIcon";
import { getScope, getUser, logout, setUser } from "../../services/auth";
import api from "../../services/api";
import ConfirmModal from "../ConfirmModal";
import { blue, deepPurple, purple } from "@material-ui/core/colors";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: "#2E2E2E",
		border: "none",
		margin: "12px",
		borderRadius: "12px",
		height: "calc(100% - 24px)"
	},
	toolbar: {
		...theme.mixins.toolbar,
		display: 'flex',
		alignItems: 'center',
	},
	me: {
		display: 'flex',
		alignItems: 'center',
		minHeight: "80px",
		margin: "0px 16px",
		color: "#FFF",
	},
	meTitle: {
		marginLeft: "8px"
	},
	logo: {
		margin: "10px auto"
	},
}));
const Menu = ({ open, setOpen }) => {
	const container = window !== undefined ? () => window.document.body : undefined;
	const [redirect, setRedirect] = React.useState({ to: null });
	const classes = useStyles();
	const [openConfirm, setOpenConfirm] = useState(false);

	const menuLinks = [
		{
			href: '/',
			text: 'Dashboard',
			icon: <Dashboard />,
			exact: true,
			strict: true,
			profile: ["ADMINISTRATOR", "JANITOR"],
		},
		{
			href: '/correspondencia/',
			text: 'Correspondências',
			icon: <LocalShipping />,
			exact: false,
			strict: true,
			profile: ["JANITOR"],
		},
		{
			href: '/notificacoes/',
			text: 'Notificações',
			icon: <DynamicFeed />,
			exact: false,
			strict: true,
			profile: ["JANITOR"],
		},
		{
			href: '/eventos/',
			text: 'Eventos',
			icon: <EventSharp />,
			exact: false,
			strict: true,
			profile: ["JANITOR"],
		},
		{
			href: '/transparencia/',
			text: 'Transparências',
			icon: <MonetizationOnOutlined />,
			exact: false,
			strict: true,
			profile: ["JANITOR"],
		},
		{
			href: '/manutencao/',
			text: 'Manutenções',
			icon: <Build />,
			exact: false,
			strict: true,
			profile: ["JANITOR"],
		},
		{
			href: '/usuario/',
			text: 'Usuários',
			icon: <PeopleSharp />,
			exact: false,
			strict: true,
			profile: ["ADMINISTRATOR"],
		},
		{
			href: '/minha-conta/',
			text: 'Minha Conta',
			icon: <AccountCircleRounded />,
			exact: false,
			strict: true,
			profile: ["ADMINISTRATOR", "JANITOR"],
		}
	];
	const [me, setMe] = React.useState(getUser() || { name: "" });
	if (!getUser()) {
		api.get(`user/me`)
			.then((response) => {
				if (response.status === 200) {
					setUser(response.data);
					setMe(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	const LogOut = () => {
		logout();
		setRedirect({ to: '/login/' });
	}
	const drawerMenu = (
		<React.Fragment>
			<List className="sidenav">
				{
					menuLinks.filter(menu => (menu.profile.indexOf(getScope()) > -1)).map((link, index) => (
						<NavLink strict={link.strict} exact={link.exact} to={link.href} key={index} className="menu" activeClassName="active">
							<ListItem button>
								<ListItemIcon>{link.icon}</ListItemIcon>
								<ListItemText primary={link.text} />
							</ListItem>
						</NavLink>
					))
				}
				<ListItem button className="menu" onClick={() => setOpenConfirm(true)}>
					<ListItemIcon><ExitToApp /></ListItemIcon>
					<ListItemText primary={"Sair"} />
				</ListItem>
			</List>
			<ConfirmModal
				yes="Sim"
				no="Não"
				title="Encerrar sessão"
				content="Deseja realmente sair e encerrar sua sessão?"
				onCancel={() => setOpenConfirm(false)}
				onConfirm={() => LogOut()}
				open={openConfirm}
			/>
		</React.Fragment>
	)
	if (redirect.to) {
		return (<Redirect push to={redirect.to} />);
	}
	return (
		<React.Fragment>
			<nav className={classes.drawer} aria-label="menu">
				<Hidden smUp implementation="css">
					<Drawer
						container={container}
						variant="temporary"
						anchor='left'
						open={open}
						onClose={() => setOpen(false)}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true,
						}}
					>
						{drawerMenu}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawerMenu}
					</Drawer>
				</Hidden>
			</nav>
		</React.Fragment>
	);
}
export default Menu;
