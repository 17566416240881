import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	IconButton,
	makeStyles,
	Slide,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import api from "../../services/api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	title: {
		flex: 1,
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
		color: theme.palette.common.white,
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[600],
		},
	},
}));
const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});
const BaixaModal = ({ open = false, handleClose, correspondencia = {}, moradores = [], morador }) => {
	useEffect(() => {
		const onHashChange = () => {
			if (window.location.hash !== "#entregar") {
				handlerClose();
				console.log("FECHOU");
			}
		};
		window.addEventListener("hashchange", onHashChange);
		return () => window.removeEventListener("hashchange", onHashChange);
	}, []);
	const handlerClose = () => {
		setFormErrors({
			picked_at: false,
			picked_by: false,
		});
		setIsSaving(false);
		handleClose();
	};
	const classes = useStyles();
	const [isSaving, setIsSaving] = useState(false);
	const [formErrors, setFormErrors] = useState({
		picked_at: false,
		picked_by: false,
	});
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = (e) => {
		e.preventDefault();
		// valida
		let erros = {
			picked_at: false,
			picked_by: false,
		};
		if (!morador) {
			erros.picked_by = "Campo obrigatório";
		}
		let formData = new FormData(e.target);
		if (!formData.get("picked_at") || dayjs(formData.get("picked_at")).isValid() == false) {
			erros.picked_at = "Campo obrigatório";
		} else if (dayjs(formData.get("picked_at")).isValid() && dayjs(formData.get("picked_at")).isAfter(new Date())) {
			erros.picked_at = "Não é possível incluir uma data futura";
		}
		if (erros.picked_at || erros.picked_by) {
			setFormErrors(erros);
		} else {
			setIsSaving(true);
			correspondencia.picked_at = formData.get("picked_at");
			correspondencia.picked_by = morador.id;
			api({
				method: "PUT",
				data: correspondencia,
				url: `/correspondence/${correspondencia.id}`,
			})
				.then((response) => {
					if (response.status === 200) {
						enqueueSnackbar("Baixado!", {
							variant: "success",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
					}
					handlerClose();
				})
				.catch((error) => {
					if (error.response.status !== 500) {
						enqueueSnackbar(error.response.data.msg, {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
					}
					handlerClose();
				});
		}
	};

	return (
		<Dialog maxWidth="md" fullWidth fullScreen={true} open={open} onClose={handlerClose} scroll="body" TransitionComponent={Transition}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<IconButton edge="start" color="inherit" onClick={handlerClose} aria-label="close">
						<CloseIcon />
					</IconButton>
					<Typography variant="h6" className={classes.title}>
						Correspondência
					</Typography>
				</Toolbar>
			</AppBar>
			<form onSubmit={onSubmit}>
				<DialogContent dividers>
					<Grid container spacing={3}>
						{correspondencia?.file ? (
							<Grid item xs={12}>
								<a href={correspondencia.file} target="_blank">
									<img src={correspondencia.file} style={{ maxWidth: 320, width: "100%" }} />
								</a>
							</Grid>
						) : null}
						<Grid item xs={12}>
							<TextField
								label="Destinatário"
								defaultValue={correspondencia?.receiver?.name}
								variant="outlined"
								fullWidth
								name="receiver"
								InputProps={{
									disabled: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Remetente"
								defaultValue={correspondencia?.sender}
								variant="outlined"
								name="sender"
								fullWidth
								InputProps={{
									disabled: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Nota"
								defaultValue={correspondencia?.note}
								variant="outlined"
								name="note"
								fullWidth
								InputProps={{
									disabled: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Recebido em"
								defaultValue={dayjs(correspondencia?.received_at).format("DD/MM/YYYY HH:mm")}
								variant="outlined"
								name="received_at"
								fullWidth
								InputProps={{
									disabled: true,
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="datetime-local"
								label="Retirado em"
								defaultValue={dayjs(new Date()).format("YYYY-MM-DD HH:mm")}
								variant="outlined"
								name="picked_at"
								fullWidth
								autoFocus
								helperText={formErrors.picked_at}
								error={formErrors.picked_at ? true : false}
							/>
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								openText="Procurar"
								clearText="Limpar"
								options={moradores}
								getOptionLabel={(morador) => `${morador.building ? morador.building + " - " : ""}${morador.name}`.trim()}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Retirado por"
										helperText={formErrors.picked_by}
										error={formErrors.picked_by ? true : false}
										variant="outlined"
										inputProps={{
											...params.inputProps,
											autoComplete: "disabled", // disable autocomplete and autofill
										}}
									/>
								)}
								noOptionsText="Nenhum resultado"
								fullWidth
								name="picked_by"
								defaultValue={morador || null}
								onChange={(event, data) => {
									morador = data;
								}}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions style={{ textAlign: "center" }}>
					<Button variant="contained" type="submit" size="large" color="secondary" fullWidth>
						Entregar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default BaixaModal;
