import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Nav from "../../components/Nav";
import {
	Backdrop,
	CircularProgress,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Hidden,
	Typography,
	Fab,
	Zoom,
	useTheme,
	Tooltip,
	FormControlLabel,
	Switch,
	MenuItem,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	List,
	ListItem,
	Divider,
} from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import api from "../../services/api";
import { Controller, useForm } from "react-hook-form";
import { Check, Delete } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { green, red } from "@material-ui/core/colors";

import { useSnackbar } from "notistack";
import ConfirmModal from "../../components/ConfirmModal";
import ReactHookFormSelect from "../../components/ReactHookFormSelect";
import Estados from "../../utils/Estados";
import InputMasker from "../../components/InputMasker";
import axios from "axios";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import dayjs from "dayjs";
import ControlledAutocomplete from "../../components/ControlledAutocomplete";

const useStyles = makeStyles((theme) => ({
	toolbar: {
		...theme.mixins.toolbar,
	},
	paperForm: {
		padding: theme.spacing(3),
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
		color: theme.palette.common.white,
		backgroundColor: green[500],
		"&:hover": {
			backgroundColor: green[600],
		},
	},
	fabDelete: {
		position: "fixed",
		bottom: 100,
		right: theme.spacing(4),
		color: theme.palette.common.white,
		backgroundColor: red[500],
		"&:hover": {
			backgroundColor: red[600],
		},
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#FFF",
		[theme.breakpoints.up("md")]: {
			left: 240,
		},
		top: 90,
	},
}));

const CorrespondenciaForm = (props) => {
	var { id } = useParams();
	id = parseInt(id) || 0;
	props.setTitle("Corrêspondencias");

	const [data, setData] = useState({
		correspondencia: { id: 0 },
		morador: false,
	});
	const [moradores, setMoradores] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const [analyzing, setAnalyzing] = useState(false);
	const [openMatches, setOpenMatches] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [redirect, setRedirect] = useState({ to: "" });
	const [matches, setMatches] = useState([]);
	const schema = yup.object().shape({
		receiver: yup.object().nullable().required("Campo Obrigatório"),
		picked_by: yup.object().nullable(),
		sender: yup.string().required("Campo Obrigatório"),
		type: yup.string().required("Campo Obrigatório"),
		received_at: yup.date().max(new Date(), "Não é possível incluir uma data futura").required("Campo Obrigatório"),
		picked_at: yup.date().nullable().max(new Date(), "Não é possível incluir uma data futura"),
	});
	const methods = useForm({
		resolver: yupResolver(schema),
	});

	const theme = useTheme();

	const { enqueueSnackbar } = useSnackbar();

	const { handleSubmit, control, reset, errors } = methods;
	const classes = useStyles();

	const transitionDuration = {
		enter: theme.transitions.duration.enteringScreen,
		exit: theme.transitions.duration.leavingScreen,
	};
	const handleOpenConfirm = () => {
		setOpenConfirm(true); // abre modal de confirmação
	};
	const closeOpenConfirm = () => {
		setOpenConfirm(false); // fecha modal de confirmação
	};
	const deleteCorrespondencia = (id) => {
		closeOpenConfirm();
		api.delete(`/correspondence/${id}`)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar("Correspondência removida!", {
						variant: "success",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/correspondencia/" });
				}
			})
			.catch((error) => {
				if (error.response.status !== 500) {
					enqueueSnackbar(error.response.data.msg, {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				}
			});
	};
	const onSubmit = (_data, event) => {
		setIsSaving(true);
		_data = { ...data.correspondencia, ..._data };
		let formdata = new FormData(event.target);
		formdata.set("received_at", dayjs(_data.received_at).toISOString());
		formdata.append("id_habitant", _data.receiver.id);
		formdata.append("id_building", _data.receiver.id_building);
		formdata.append("type", _data.type);
		if (_data.picked_at && dayjs(_data.picked_at).isValid()) {
			try {
				formdata.append("picked_by", _data.picked_by.id);
				formdata.set("picked_at", dayjs(_data.picked_at).toISOString() || "");
			} catch (error) {
				console.log(error)
			}
		}
		let url = `/correspondence`;	
		if (_data.id) url += `/${_data.id}`;
		api({
			method: _data.id ? "PUT" : "POST",
			data: formdata,
			url: url,
		})
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar("Correspondência salva!", {
						variant: "success",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/correspondencia/" });
				}
				setIsSaving(false);
			})
			.catch((error) => {
				if (error.response.status !== 500) {
					enqueueSnackbar(error.response.data.msg, {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				}
				setIsSaving(false);
			});
	};
	useEffect(() => {
		async function getData() {
			let _moradores = [];
			try {
				const response = await api.get(`/habitant/`);
				_moradores = response.data.data;
				setMoradores(response.data.data);
				reset({ ...response.data });
			} catch (error) {
				if (error.response.status === 403) {
					enqueueSnackbar("Você não tem permissão", {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/correspondencia/" });
				}
				if (error.response.status === 404) {
					enqueueSnackbar("Correspondência não encontrada", {
						variant: "error",
						autoHideDuration: 3000,
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
					setRedirect({ to: "/correspondencia/" });
				}
			}
			if (id) {
				try {
					const response = await api.get(`/correspondence/${id}`);
					let data = response.data;
					data.receiver = _moradores.find((morador) => morador.id == data.id_habitant);
					data.received_at = dayjs(data.received_at).format("YYYY-MM-DD HH:mm");
					data.picked_at = data.picked_at ? dayjs(data.picked_at).format("YYYY-MM-DD HH:mm") : null;
					if (data.picked_by && data.picked_by.id) {
						data.picked_by = _moradores.find((morador) => morador.id == data.picked_by.id);
					}
					setData({
						...data,
						correspondencia: response.data,
					});
					reset({ ...response.data });
				} catch (error) {
					if (error.response.status === 403) {
						enqueueSnackbar("Você não tem permissão", {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
						setRedirect({ to: "/correspondencia/" });
					}
					if (error.response.status === 404) {
						enqueueSnackbar("Correspondência não encontrada", {
							variant: "error",
							autoHideDuration: 3000,
							anchorOrigin: { vertical: "top", horizontal: "right" },
						});
						setRedirect({ to: "/correspondencia/" });
					}
				}
			}
			setLoading(false);
		}
		getData();
	}, []);

	if (redirect.to) {
		return <Redirect push to={redirect.to} />;
	}
	const analyzeImage = async (e) => {
		let file = e.target.files ? e.target.files[0] : false;
		setOpenMatches(true);
		setMatches([]);

		if (file) {
			setAnalyzing(true);
			let formData = new FormData();
			formData.append("image", file);
			try {
				const response = await api.post("/correspondence/analyze/image", formData);
				let matches = response.data.matches;
				if (matches.length == 1) {
					control.setValue(
						"receiver",
						moradores.find((morador) => morador.id == matches[0].id)
					);
					setOpenMatches(false);
				}
				control.setValue("note", response.data.note || "");
				setMatches(matches.length ? matches : false);
				setAnalyzing(false);
			} catch (error) {
				alert("Erro");
			}
		}
	};
	const selectMoradorMatch = (match) => {
		/* setData({
            ...data,
            morador: moradores.find(morador => morador.id == match.id)
        }); */
		control.setValue(
			"receiver",
			moradores.find((morador) => morador.id == match.id)
		);
		setOpenMatches(false);
	};

	return (
		<>
			{loading ? (
				<Grid container spacing={2}>
					<Grid item xs={12} style={{ height: "60vh" }}>
						<Skeleton variant="rect" width="100%" height="100%" style={{ borderRadius: "30px" }} />
					</Grid>
				</Grid>
			) : (
				<>
					<Hidden smUp>
						<Grid container className="page-title" alignItems="center" justify="space-between">
							<Grid item xs={12}>
								<Typography variant="h5" display="block" noWrap>
									{id
										? `${
												data.correspondencia.sender
													? `${data.correspondencia.sender} > ${data.correspondencia.type || ""}`.trim()
													: ""
										  }`
										: "Nova Correspondência"}
								</Typography>
							</Grid>
						</Grid>
					</Hidden>
					<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Paper elevation={3} className={classes.paperForm}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<input
												accept="image/*"
												id="image"
												multiple={false}
												type="file"
												name="image"
												style={{ display: "none" }}
												onChange={analyzeImage}
												capture="camera"
											/>
											<label htmlFor="image">
												<Button variant="contained" color="primary" component="span">
													Clique aqui para scanear a correspondência
												</Button>
											</label>
										</Grid>
										{data.correspondencia.file ? (
											<Grid item xs={12}>
												<a href={data.correspondencia.file} target="_blank">
													<img src={data.correspondencia.file} style={{ maxWidth: 320, width: "100%" }} />
												</a>
											</Grid>
										) : null}
										<Grid item xs={12}>
											<ControlledAutocomplete
												openText="Procurar"
												clearText="Limpar"
												options={moradores}
												getOptionLabel={(morador) =>
													`${morador.building ? morador.building + " - " : ""}${morador.name}`.trim()
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Selecione o morador/destinatário"
														helperText={errors.receiver?.message}
														error={errors.receiver ? true : false}
														variant="outlined"
														inputProps={{
															...params.inputProps,
															autoComplete: "disabled",
														}}
													/>
												)}
												noOptionsText="Nenhum resultado"
												fullWidth
												control={control}
												name="receiver"
												// value={data.morador || null}
												defaultValue={data.morador || null}
												// value={data.morador || {}}

												onChange={([event, data]) => {
													return data;
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												as={TextField}
												fullWidth
												name="sender"
												label="Remetente"
												error={errors.sender ? true : false}
												helperText={errors.sender?.message}
												control={control}
												variant="outlined"
												defaultValue=""
											/>
										</Grid>
										<Grid item xs={12}>
											<Controller
												as={TextField}
												fullWidth
												name="note"
												label="Nota"
												control={control}
												variant="outlined"
												defaultValue=""
											/>
										</Grid>
										<Grid item xs={12}>
											<ReactHookFormSelect
												id="type"
												name="type"
												label="Tipo"
												control={control}
												variant="outlined"
												fullWidth
												defaultValue={"PACKAGE"}
											>
												<MenuItem key={0} value="PACKAGE">
													PACOTE
												</MenuItem>
												<MenuItem key={1} value="LETTER">
													CARTA
												</MenuItem>
											</ReactHookFormSelect>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												as={TextField}
												type="datetime-local"
												fullWidth
												name="received_at"
												label="Recebido em"
												error={errors.received_at ? true : false}
												helperText={errors.received_at?.message}
												control={control}
												variant="outlined"
												defaultValue={
													dayjs(data.correspondencia.received_at).format("YYYY-MM-DD HH:mm") ||
													dayjs(new Date()).format("YYYY-MM-DD HH:mm")
												}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<Controller
												as={TextField}
												type="datetime-local"
												fullWidth
												name="picked_at"
												label="Retirado em"
												error={errors.picked_at ? true : false}
												helperText={errors.picked_at?.message}
												control={control}
												variant="outlined"
												defaultValue={
													data.correspondencia.picked_at
														? dayjs(data.correspondencia.picked_at).format("YYYY-MM-DD HH:mm")
														: undefined
												}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</Grid>
										<Grid item xs={12}>
											<ControlledAutocomplete
												openText="Procurar"
												clearText="Limpar"
												options={moradores}
												getOptionLabel={(morador) =>
													`${morador.building ? morador.building + " - " : ""}${morador.name}`.trim()
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Retirado por"
														helperText={errors.picked_by?.message}
														error={errors.picked_by ? true : false}
														variant="outlined"
														inputProps={{
															...params.inputProps,
															autoComplete: "disabled",
														}}
													/>
												)}
												noOptionsText="Nenhum resultado"
												fullWidth
												control={control}
												name="picked_by"
												// value={data.morador || null}
												defaultValue={data.morador || null}
												// value={data.morador || {}}

												onChange={([event, data]) => {
													return data;
												}}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
						<Zoom in={true} timeout={transitionDuration}>
							<Tooltip title="Salvar">
								<Fab type="submit" className={classes.fab}>
									{!isSaving ? <Check></Check> : <CircularProgress style={{ width: 16, height: 16 }} />}
								</Fab>
							</Tooltip>
						</Zoom>
						{id ? (
							<Zoom in={true} timeout={transitionDuration}>
								<Tooltip title="Remover">
									<Fab onClick={() => handleOpenConfirm()} className={classes.fabDelete}>
										<Delete></Delete>
									</Fab>
								</Tooltip>
							</Zoom>
						) : null}
					</form>
					<ConfirmModal
						yes="Sim"
						no="Não"
						title="Remover"
						content="Deseja remover essa correspondência?"
						onCancel={closeOpenConfirm}
						onConfirm={() => deleteCorrespondencia(id)}
						open={openConfirm}
					/>
					<Dialog
						fullWidth
						maxWidth="xs"
						aria-labelledby="confirmation-dialog-title"
						open={openMatches}
						// onClose={handleCancel}
						// {...other}
					>
						<DialogTitle id="confirmation-dialog-title">{analyzing ? "Scaneando" : "Scan concluído"}</DialogTitle>
						<DialogContent dividers style={{ textAlign: "center" }}>
							{analyzing ? (
								<CircularProgress style={{ width: 16, height: 16 }} />
							) : matches.length > 1 ? (
								<>
									Selecione o morador abaixo.
									<List style={{ width: "100%" }}>
										{matches.map((match) => (
											<>
												<ListItem button onClick={() => selectMoradorMatch(match)}>
													{match.name}
												</ListItem>
												<Divider />
											</>
										))}
									</List>
								</>
							) : (
								"Não foi encontrado resultados."
							)}
						</DialogContent>
						<DialogActions>
							<Button autoFocus onClick={() => setOpenMatches(false)} color="primary">
								Fechar
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
		</>
	);
};

export default CorrespondenciaForm;
