import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';


const useStyles = makeStyles((theme) => ({
    paper: {
        width: '80%',
        maxHeight: 435,
    },
}));

function ConfirmModal(props) {
    const classes = useStyles();
    var { open, title, content, onCancel, onConfirm, yes, no, ...other } = props;
    yes = yes || "Sim";
    no = no || "Não";
    const handleCancel = () => {
        onCancel();
    };

    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            className={classes.name}
            onClose={handleCancel}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {content}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    {no}
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    {yes}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmModal.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default ConfirmModal;


