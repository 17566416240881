import React, { Component } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import './styles.css';
export default class Toast extends Component {
    state = {
        open: true
    }
    render() {
        const { type, className, message, onClose, vertical = "top", horizontal = "right", ...other } = this.props;
        const variantIcon = {
            success: CheckCircleIcon,
            warning: WarningIcon,
            error: ErrorIcon,
            info: InfoIcon,
        };
        const Icon = variantIcon[type];

        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            this.setState({ open: false });
        };
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: vertical,
                    horizontal: horizontal,
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <SnackbarContent
                    className={type + " notification"}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className="message">
                            <Icon className="icon {variantIcon[type]}" />
                            {message}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon className="icon action" />
                        </IconButton>,
                    ]}
                    {...other}
                />
            </Snackbar>
        )
    };
}

// export default Toast;