import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import Nav from "../../components/Nav";
import { APP_NAME } from "../../config";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Divider,
	Fab,
	FormControl,
	Grid,
	Hidden,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	withStyles,
	Zoom,
} from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import api from "../../services/api";
import { Add, Search } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import dayjs from "dayjs";
import BaixaModal from "./baixaModal";

// import { Provider } from 'react-redux';
// import store from '../../store';

const classes = (theme) => ({
	toolbar: {
		...theme.mixins.toolbar,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#FFF",
		[theme.breakpoints.up("md")]: {
			left: 240,
		},
		top: 90,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
	},
	fab: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
	},
});
class CorrespondenciaList extends Component {
	state = {
		redirect: false,
		loading: true,
		correspondencias: [],
		moradores: [],
		openBaixaModal: false,
		selectedCorrespondencia: null,
	};

	selectedMorador = null;
	
	componentDidUpdate(){
		if(this.state.openBaixaModal){
			window.location.hash = "#entregar";
		}
	}

	async componentDidMount() {
		this.props.setTitle("Correspondências");
		document.title = `${APP_NAME} - Correspondências`;
		await this.getMoradores();
	}
	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect push to="/" />;
		}
	};
	async getMoradores() {
		try {
			const response = await api.get(`habitant`);
			this.setState({
				...this.state,
				moradores: response.data.data.sort(function (a, b) {
					var textA = a.building.toUpperCase();
					var textB = b.building.toUpperCase();
					return textA < textB ? -1 : textA > textB ? 1 : 0;
				}),
			});
			await this.getCorrespondencias({}, response.data.data);
		} catch (error) {
			if (error.response.status === 403) {
				this.setState({
					redirect: true,
				});
			}
		}
	}
	async getCorrespondencias(params = {}, moradores) {
		try {
			const response = await api.get(`correspondence`, { params: params });
			let correspondencias = response.data.data;
			this.setState({
				redirect: false,
				correspondencias: response.data.data,
				loading: false,
			});
		} catch (error) {
			if (error.response.status === 403) {
				this.setState({
					redirect: true,
				});
			}
		}
	}
	translateType(type) {
		if (type == "PACKAGE") {
			return "PACOTE";
		}
		return "CARTA";
	}
	lista = () => {
		const { classes } = this.props;
		const { correspondencias } = this.state;

		if (!this.state.loading && correspondencias.length === 0) {
			return (
				<TableRow>
					<TableCell colSpan={5}>
						<Alert severity="warning" className="alert-text-center">
							Nenhuma correspondencia encontrada!
						</Alert>
					</TableCell>
				</TableRow>
			);
		} else {
			return correspondencias.map((correspondencia) => (
				<TableRow hover key={correspondencia.id}>
					<TableCell data-label="Destinatário">
						<Link to={`/correspondencia/${correspondencia.id}/editar/`}>{correspondencia.receiver?.name}</Link>
					</TableCell>
					<TableCell data-label="Remetente">
						<Link to={`/correspondencia/${correspondencia.id}/editar/`}>{correspondencia.sender}</Link>
					</TableCell>
					<TableCell data-label="Recebido em">
						<Link to={`/correspondencia/${correspondencia.id}/editar/`}>
							{dayjs(correspondencia.received_at).format("DD/MM/YYYY HH:mm")}
						</Link>
					</TableCell>
					<TableCell data-label="Nota">
						<Link to={`/correspondencia/${correspondencia.id}/editar/`}>{correspondencia.note}</Link>
					</TableCell>
					<TableCell data-label="Retirado em">
						{correspondencia.picked_at ? (
							<Link to={`/correspondencia/${correspondencia.id}/editar/`}>
								{dayjs(correspondencia.picked_at).format("DD/MM/YYYY HH:mm")}
							</Link>
						) : (
							<Button
								variant="contained"
								size="small"
								color="primary"
								onClick={() => this.setState({ ...this.state, selectedCorrespondencia: correspondencia, openBaixaModal: true })}
							>
								Entregar
							</Button>
						)}
					</TableCell>
				</TableRow>
			));
		}
	};
	handleSearch = (_data) => {
		const formData = new FormData(_data.target);
		if (this.selectedMorador) formData.append("id_habitant", this.selectedMorador);
		let received_at = [];
		let picked_at = [];
		if (formData.get("received_at")) {
			received_at = [
				dayjs(formData.get("received_at")).startOf("day").toISOString(),
				dayjs(formData.get("received_at")).endOf("day").toISOString(),
			];
		}
		if (formData.get("picked_at")) {
			picked_at = [dayjs(formData.get("picked_at")).startOf("day").toISOString(), dayjs(formData.get("picked_at")).endOf("day").toISOString()];
		}
		this.getCorrespondencias(
			{
				id_habitant: formData.get("id_habitant"),
				received_at: received_at,
				picked_at: picked_at,
			},
			this.state.moradores
		);
	};
	render() {
		const { classes } = this.props;
		return (
			<>
				<Hidden smUp>
					<Grid container className="page-title" alignItems="center">
						<Grid item sm={6} xs={6}>
							<Typography variant="h5">Correspondências</Typography>
						</Grid>
					</Grid>
				</Hidden>
				<Grid container>
					<Grid item xs={12}>
						<TableContainer component={Paper} elevation={0} className="table-data table-mobile">
							<form
								onSubmit={(e) => {
									e.preventDefault();
									this.handleSearch(e);
								}}
							>
								<Grid container alignItems="center">
									<Grid item xs={12} sm={4} style={{ padding: "8px" }}>
										<Autocomplete
											openText="Procurar"
											clearText="Limpar"
											options={this.state.moradores}
											getOptionLabel={(morador) => `${morador.building ? morador.building + " - " : ""}${morador.name}`.trim()}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Filtrar por destinatário"
													variant="outlined"
													inputProps={{
														...params.inputProps,
														autoComplete: "disabled", // disable autocomplete and autofill
													}}
												/>
											)}
											noOptionsText="Nenhum resultado"
											fullWidth
											name="habitant"
											defaultValue={null}
											onChange={(e, data) => (this.selectedMorador = data ? data.id : null)}
										/>
									</Grid>
									<Grid item xs={12} sm={3} style={{ padding: "8px" }}>
										<TextField
											fullWidth
											variant="outlined"
											id="received_at"
											label="Recebido em"
											type="date"
											name="received_at"
											defaultValue=""
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={3} style={{ padding: "8px" }}>
										<TextField
											fullWidth
											variant="outlined"
											id="picket_at"
											label="Retirado em"
											type="date"
											name="picked_at"
											defaultValue=""
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={2} style={{ padding: "8px", textAlign: "center" }}>
										<Button variant="contained" color="primary" size="large" startIcon={<Search />} type="submit">
											Procurar
										</Button>
									</Grid>
								</Grid>
							</form>
							<Divider />
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Destinatário</TableCell>
										<TableCell>Remetente</TableCell>
										<TableCell>Recebido em</TableCell>
										<TableCell>Nota</TableCell>
										<TableCell>Retirado em</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>{this.lista()}</TableBody>
							</Table>
						</TableContainer>

						<Zoom in={true}>
							<Tooltip title="Adicionar">
								<Fab component={Link} to="/correspondencia/cadastro/novo" color="primary" className={classes.fab}>
									<Add htmlColor={"#FFF"}></Add>
								</Fab>
							</Tooltip>
						</Zoom>
					</Grid>
				</Grid>
				<BaixaModal
					open={this.state.openBaixaModal}
					handleClose={() => this.setState({ ...this.state, openBaixaModal: false })}
					moradores={this.state.moradores}
					morador={this.state.moradores.find((morador) => morador.id == this.state.selectedCorrespondencia?.id_habitant)}
					correspondencia={this.state.selectedCorrespondencia}
				/>
			</>
		);
	}
}

export default withStyles(classes)(CorrespondenciaList);
